import { useState } from 'react';

import { useNavigate } from "react-router-dom";

import BarLoader from "react-spinners/BarLoader";
import { Button } from 'reactstrap';

function AdminPage() {

  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  if (isLoading) return (
    <div className='loadingPage'>
      <BarLoader color="#CCA449" />
    </div>
  )

  return (
    <div id="helpPage" className='page'> 
      <h1>
        Admin
      </h1>
      <div>
        <Button onClick={() => navigate('/admin/companies')} className='primaryBtn' style={{marginBottom: '0.5rem'}}>
          Empresas
        </Button>
        <Button onClick={() => navigate('/admin/hr')} className='primaryBtn'>
          Recursos humanos
        </Button>
      </div>
    </div>
  );
}

export default AdminPage;