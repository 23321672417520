import { useState, useEffect } from 'react';

import { query, collection, getDocs, where } from "firebase/firestore";

import emailjs from "@emailjs/browser";

import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
// eslint-disable-next-line
import { auth, db } from "./util/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

import BarLoader from "react-spinners/BarLoader";
import { FiMenu } from 'react-icons/fi'
import { IoClose } from 'react-icons/io5';

import LoginPage from './pages/auth/LoginPage';
import RegisterPage from './pages/auth/RegisterPage';
import ResetPasswordPage from './pages/auth/ResetPasswordPage';

import DashboardPage from './pages/main/DashboardPage';
import AccountPage from './pages/main/AccountPage';
import HelpPage from './pages/main/HelpPage';
import RequestsPage from './pages/main/RequestsPage';
import CreateRequestPage from './pages/main/CreateRequest';
import ReportsPage from './pages/main/ReportsPage';
import AdminPage from './pages/main/AdminPage';
import AdminCompaniesPage from './pages/main/AdminCompaniesPage';
import AdminHRPage from './pages/main/AdminHRPage';
import AdminCreateCompanyPage from './pages/main/AdminCreateCompanyPage';

function App() {
  const [user, loading] = useAuthState(auth);

  (function(){
    emailjs.init("EKrRkt5k4e-ReOtzi");
  })();

  if (loading) return (
		<div className='loadingPage'>
      <BarLoader color='#CCA449' />
    </div>
	)

  return (
    user ? <MainRoutes/> : <AuthRoutes />
  );
}

function AuthRoutes() {
  return (
    <Router>
      <Routes>
        <Route path='/*' element={<Navigate to='/login' />}/>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/reset" element={<ResetPasswordPage />} />
      </Routes>
    </Router>
  )
}

function MainRoutes() {
  // eslint-disable-next-line
  const [user] = useAuthState(auth);

  const [userRole, setUserRole] = useState();
  const fetchUserData = async () => {
    try {
      setIsLoading(true);

      setTimeout(async ()=> {
        const q = query(collection(db, "users"), where("uid", "==", user?.uid));
        const doc = await getDocs(q);
        const data = doc.docs[0].data();

        setUserRole(data.role);
      }, 3000)
      
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line
  }, [user]);

  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);

  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const toggleSidebar = () => {
    setSidebarIsOpen(!sidebarIsOpen);
  }

  if (isLoading) return (
    <div className='loadingPage'>
      <BarLoader color="#CCA449" />
    </div>
  )

  return (
    <div className="App">
      {
        sidebarIsOpen ?
          <div id='sidebar'>
            <div id='sidebarContainer'>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'end'}}>
                <IoClose onClick={toggleSidebar}/>
              </div>
              {
                userRole === 'admin' ?
                  <a className='navbarItem' href='/admin'>
                    Admin
                  </a>
                : null
              }
              {
                ((userRole === 'hr') || (userRole === 'hr')) ?
                  <a className='navbarItem' href='/reports'>
                    Reportes
                  </a>
                : null
              }
              <a className='navbarItem' href='/help'>
                Ayuda
              </a>
              <a className='navbarItem' href='/account'>
                Cuenta
              </a>
            </div>
          </div>
        : null
      }
      <section>
        <header>
          <a href='/'>
          <img
            alt="logo"
            src="https://firebasestorage.googleapis.com/v0/b/grupo-maritimo.appspot.com/o/assets%2Flogogm2020%20(1).PNG?alt=media&token=5e2c58ff-e31b-480b-9188-5fecdecbf81a"
            style={{
              height: 50,
            }}
          />
          </a>
          <div id='desktopNavbarItems' style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
            {
              userRole === 'admin' ?
                <a className='navbarItem' href='/admin'>
                  Admin
                </a>
              : null
            }
            {
              ((userRole === 'hr') || (userRole === 'admin')) ?
                <a className='navbarItem' href='/reports'>
                  Reportes
                </a>
              : null
            }
            <a className='navbarItem' href='/help'>
              Ayuda
            </a>
            <a className='navbarItem' href='/account'>
              Cuenta
            </a>
          </div>
          <FiMenu id='menuToggler' onClick={toggleSidebar} />
        </header> 
        <Router>
          <Routes>
            <Route path='/login' element={<Navigate to='/' />}/>
            <Route path='/register' element={<Navigate to='/' />}/>
            <Route path='/reset' element={<Navigate to='/' />}/>
            <Route path="/" element={<DashboardPage />} />
            <Route path="/requests" element={<RequestsPage />} />
            <Route path="/createRequest" element={<CreateRequestPage />} />
            <Route path="/help" element={<HelpPage />} />
            <Route path="/reports" element={<ReportsPage />} />
            <Route path="/account" element={<AccountPage />} />
            {
              userRole === 'admin' ?
                <>
                  <Route path="/admin" element={<AdminPage />} />
                  <Route path="/admin/companies" element={<AdminCompaniesPage />} />
                  <Route path="/admin/createCompany" element={<AdminCreateCompanyPage />} />
                  <Route path="/admin/hr" element={<AdminHRPage />} />
                </>
              : null
            }
          </Routes>
        </Router>
      </section>
      <footer id='desktopFooter'>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <p>
            ⓒ 2023 Grupo Maritimo
          </p>
          <a href='/'>
            Terminos y condiciones
          </a>
          <a href='/'>
            Politica de privacidad
          </a>
        </div>
        {/* <div style={{display: 'flex', flexDirection: 'row'}}>
          <div className='socialsIconContainer'>
            <BsInstagram />
          </div>
          <div className='socialsIconContainer'>
            <FaTiktok />
          </div>
          <div className='socialsIconContainer'>
            <BsYoutube />
          </div>
          <div className='socialsIconContainer'>
            <FaFacebookF />
          </div>
        </div> */}
      </footer>
      <footer id='mobileFooter'>
        {/* <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginBottom: '2rem'}}>
          <div className='socialsIconContainer'>
            <BsInstagram />
          </div>
          <div className='socialsIconContainer'>
            <FaTiktok />
          </div>
          <div className='socialsIconContainer'>
            <BsYoutube />
          </div>
          <div className='socialsIconContainer'>
            <FaFacebookF />
          </div>
        </div> */}
        <a href='/' style={{marginBottom: '1rem'}}>
          Terminos y condiciones
        </a>
        <a href='/' style={{marginBottom: '1rem'}}>
          Politica de privacidad
        </a>
        <p>
          ⓒ 2023 Grupo Maritimo
        </p>
      </footer>
    </div>
  );
}

export default App;
