import { useState, useEffect } from 'react';

import Swal from 'sweetalert2';

import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../util/firebase";
import { query, collection, getDocs, where, doc, updateDoc, addDoc, deleteDoc } from "firebase/firestore";

import BarLoader from "react-spinners/BarLoader";

import { Modal, ModalBody, Input, Button } from 'reactstrap';

import {DatePicker} from 'reactstrap-date-picker'

import { 
  PiUserCirclePlusBold,
  PiHandBold,
  PiPlusCircleBold} from 'react-icons/pi';
import { IoClose } from 'react-icons/io5';

import { DataGrid } from '@mui/x-data-grid';

import { Chart } from "react-google-charts";

function DashboardPage() {
  const [user] = useAuthState(auth);

  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false)

  const [userData, setUserData] = useState();
  const fetchUserData = async () => {
    try {
      setIsLoading(true);
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setUserData(data);
      setIsLoading(false);
    } catch (err) {
      fetchUserData()
      // console.error(err);
      // alert("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line
  }, []);

  const [newUserName, setNewUserName] = useState();
  const [newUserEmail, setNewUserEmail] = useState();
  const [newUserPhoneNumber, setNewUserPhoneNumber] = useState();
  // eslint-disable-next-line
  const [newUserPersonalId, setNewUserPersonalId] = useState();
  const [newUserCompany, setNewUserCompany] = useState();
  const [newUserDateOfEntry, setNewUserDateOfEntry] = useState();
  const [newUserLeader, setNewUserLeader] = useState();
  const [newUserRole, setNewUserRole] = useState();
  const [newUserAccountantName, setNewUserAccountantName] = useState();
  const [newUserAccountantEmail, setNewUserAccountantEmail] = useState();

  const [addUserModal, setAddUserModal] = useState(false);
  const toggleAddUserModal = () => {
    setAddUserModal(!addUserModal);
  }

  // eslint-disable-next-line
  const [employeeForModal, setEmployeeForModal] = useState();
  const [employeeForModalId, setEmployeeForModalId] = useState();
  const [employeeForModalName, setEmployeeForModalName] = useState();
  const [employeeForModalEmail, setEmployeeForModalEmail] = useState();
  const [employeeForModalCompany, setEmployeeForModalCompany] = useState();
  const [employeeForModalDateOfEntry, setEmployeeForModalDateOfEntry] = useState();
  const [employeeForModalLeader, setEmployeeForModalLeader] = useState();
  const [employeeForModalStatus, setEmployeeForModalStatus] = useState();
  const [employeeForModalHolidaysTaken, setEmployeeForModalHolidaysTaken] = useState();
  const [employeeForModalCurrentPeriod, setEmployeeForModalCurrentPeriod] = useState();
  const [employeeForModalHolidaysLeft, setEmployeeForModalHolidaysLeft] = useState();
  const [employeeForModalAccountantName, setEmployeeForModalAccountantName] = useState();
  const [employeeForModalAccountantEmail, setEmployeeForModalAccountantEmail] = useState();
  const [employeeForModalRequests, setEmployeeForModalRequests] = useState();

  const [graphData, setGraphData] = useState()
  const getGraphData = async (employeeUid) => {
    if (employeeUid){
    let numOfIncapacities = 0;
    let numOfPermits = 0;
    let numOfHolidays = 0;

    try {
      setIsLoading(true);
  
      const requestsRef = collection(db, "requests");
      const q = query(requestsRef, where("employeeUid", "==", employeeUid))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const newRequest = doc.data()
        if (newRequest.type === 'Incapacidad'){
          if (newRequest.status === 'Aprobada'){
            numOfIncapacities += 1;
          }
        } else if (newRequest.type === 'Permiso'){
          if (newRequest.status === 'Aprobada'){
            numOfPermits += 1;
          }
        } else if (newRequest.type === 'Vacacion'){
          if (newRequest.status === 'Aprobada'){
            numOfHolidays += 1;
          }
        }
      });

      const newGraphData = [
        ["Tipo", "Dias", { role: "style" }],
        ["Incapacidad", numOfIncapacities, "#FFE783"],
        ["Permisos", numOfPermits, "#CCA449"],
        ["Vacación", numOfHolidays, "#BF8D34"],
      ]
  
      setGraphData(newGraphData);
  
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching members");
    }
    }
  }
  //   ["Tipo", "Dias", { role: "style" }],
  //   ["Incapacidad", 1, "#000000"],
  //   ["Permisos", 2, "#000000"],
  //   ["Vacación", 1, "#000000"],
  // ];

  const fetchSingleEmployeesRequests = async (employeeUid) => {
    if (employeeUid){
    try {
      setIsLoading(true);

      let fetchedRequests = [];
      const requestsRef = collection(db, "requests");
      const q = query(requestsRef, where("employeeUid", "==", employeeUid))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const newRequest = doc.data()
        fetchedRequests.push(newRequest)
      });

      setEmployeeForModalRequests(fetchedRequests);

      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching requests");
    }
    }
  };

  const [employeeModal, setEmployeeModal] = useState(false);
  const toggleEmployeeModal = (employee) => {
    // if (employee.status !== 'Pendiente'){
    if (employeeModal){
      setEmployeeModal(false);
      setEmployeeForModalId();
      setEmployeeForModalName();
      setEmployeeForModalEmail();
      setEmployeeForModalCompany();
      setEmployeeForModalDateOfEntry();
      setEmployeeForModalLeader();
      setEmployeeForModalStatus();
      setEmployeeForModalHolidaysTaken();
      setEmployeeForModalCurrentPeriod();
      setEmployeeForModalHolidaysLeft();
      setEmployeeForModalAccountantName();
      setEmployeeForModalAccountantEmail();
      setEmployeeForModalRequests();
    } else {
      setEmployeeForModal(employee);
      setEmployeeForModalId(employee.id);
      setEmployeeForModalName(employee.name);
      setEmployeeForModalEmail(employee.email);
      setEmployeeForModalCompany(employee.company);
      setEmployeeForModalDateOfEntry(employee.dateOfEntry);
      setEmployeeForModalLeader(employee.leader);
      setEmployeeForModalStatus(employee.status);
      setEmployeeForModalAccountantName(employee.accountantName);
      setEmployeeForModalAccountantEmail(employee.accountantEmail);
      setEmployeeForModalHolidaysTaken(employee.holidaysTaken);
      setEmployeeForModalCurrentPeriod(employee.currentPeriod);
      setEmployeeForModalHolidaysLeft(employee.holidaysLeft);
      fetchSingleEmployeesRequests(employee.uid);
      getGraphData(employee.uid)
      setEmployeeModal(true);
    }
    // } else {
    //   Swal.fire({
    //     text: "El usuario sigue pendiente a completar su cuenta",
    //     icon: 'warning',
    //     confirmButtonColor: '#CCA449',
    //     confirmButtonText: "Okay",
    //   })
    // }
  }

  const handleEvent = (
    params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details, // GridCallbackDetails
  ) => {
    toggleEmployeeModal(params.row)
  };

  // eslint-disable-next-line
  const [requests, setRequests] = useState();
  const fetchRequests = async () => {
    try {
      setIsLoading(true);

      let fetchedRequests = [];
      const requestsRef = collection(db, "requests");
      const q = query(requestsRef, where("employeeUid", "==", user.uid))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const newRequest = doc.data()
        fetchedRequests.push(newRequest)
      });



      setRequests(fetchedRequests);

      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching requests");
    }
  };

  useEffect(() => {
    fetchRequests();
    // eslint-disable-next-line
  }, []);

  const [selectedRequest, setSelectedRequest] = useState();
  const [requestModal, setRequestModal] = useState(false);
  const toggleRequestModal = (request) => {
    if (!requestModal){
      setSelectedRequest(request)
      setRequestModal(true);
    } else {
      setRequestModal(false);
    }
  }

  const [members, setMembers] = useState([
    // { id: 1, name: 'Diego Spranger', company: 'ABC', dateOfEntry: new Date(), leader: 'Nayib Bukele', status: 'Activo', holidaysTaken: 5, currentPeriod: '2023', holidaysLeft: 10},
    // { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
    // { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
    // { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
    // { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
    // { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
    // { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
    // { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
    // { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
  ]);

  // eslint-disable-next-line
  const [companyOptions, setCompanyOptions] = useState();
  const fetchCompanies = async () => {
    try {
      setIsLoading(true);
  
      let fetchedCompanies = [];
      const companiesRef = collection(db, "companies");
      const q = query(companiesRef)
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const newCompany = doc.data()
        fetchedCompanies.push(newCompany)
      });
  
      setCompanyOptions(fetchedCompanies);
  
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching companies");
    }
  }
  useEffect(() => {    
    fetchCompanies();
  }, []);

  // eslint-disable-next-line
  const [leaderOptions, setLeaderOptions] = useState([
    // {
    //   name: 'Miguel Flores Carballo',
    //   email: 'miguel.florescarballo@gmaritimo.com'
    // },
    // {
    //   name: 'Maria Teresa de Arevalo',
    //   email: 'maria.arevalo@gmaritimo.com'
    // },
    // {
    //   name: 'Miguel Flores Rivas',
    //   email: 'miguel.flores@gmaritimo.com'
    // },
    // {
    //   name: 'Ronald Mauricio Batres',
    //   email: 'ronald.batres@gmaritimo.com'
    // },
    // {
    //   name: 'Yesly Murga',
    //   email: 'yesly.murga@gmaritimo.com'
    // },
    // {
    //   name: 'Astrid Montoya',
    //   email: 'astrid.montoya@gmaritimo.com'
    // },
    // {
    //   name: 'Ana Camila Guzman Corleto',
    //   email: 'camila.guzman@gmaritimo.com'
    // },
    // {
    //   name: 'Miguel Flores Figueroa',
    //   email: 'miguel.floresfigueroa@gmaritimo.com'
    // },
    // {
    //   name: 'Felix Membreño',
    //   email: 'felix.membreno@gmaritimo.com'
    // },
  ]);

  const fetchMembers = async (userData) => {
    try {
      setIsLoading(true);
  
      let fetchedMembers = [];
      let fetchedLeaders = [];
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("email", "!=", userData?.email))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const newUser = doc.data();
        if (newUser.role === 'leader'){
          fetchedLeaders.push(
            {
              name: newUser.name,
              email: newUser.email
            }
          )
        }
        fetchedMembers.push(newUser)
      });
      
      setMembers(fetchedMembers);
      console.log(fetchedLeaders);
      setLeaderOptions(fetchedLeaders);
  
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching members");
    }
  };

  useEffect(() => {
    if (userData){
      fetchMembers(userData);
    }
  }, [userData]);

  const changeEmployeeData = async () => {
    setIsLoading(true);

    try {
      const userRef = query(collection(db, "users"), where("email", "==", employeeForModalEmail));
      const findUsers = await getDocs(userRef);
      findUsers.forEach( async (user) => {
        const getUser = doc(db, 'users', user.id);
        const newLeaderObject = leaderOptions.find(x => x.name === employeeForModalLeader);
        await updateDoc(getUser, {
          company: employeeForModalCompany,
          email: employeeForModalEmail,
          leader: employeeForModalLeader,
          leaderEmail: newLeaderObject.email,
          status: employeeForModalStatus,
          holidaysTaken: parseFloat(employeeForModalHolidaysTaken),
          currentPeriod: employeeForModalCurrentPeriod,
          holidaysLeft: (15 - parseFloat(employeeForModalHolidaysTaken)),
          accountantName: employeeForModalAccountantName ? employeeForModalAccountantName : '',
          accountantEmail: employeeForModalAccountantEmail ? employeeForModalAccountantEmail: '',
          dateOfEntry: employeeForModalDateOfEntry
        });
      });
      fetchMembers(userData);
      toggleEmployeeModal();
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
    
    setIsLoading(false);
  }

  const [userIdCounter, setUserIdCounter] = useState();
  const fetchUserIdCounter = async () => {
    try {
      setIsLoading(true);

      const q = query(collection(db, "counters"), where("name", "==", "userId"));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setUserIdCounter(data);

      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching counter");
    }
  };

  useEffect(() => {
    fetchUserIdCounter();
    // eslint-disable-next-line
  }, []);

  const createUser = async () => {
    setIsLoading(true);

    try {
      const newLeaderObject = leaderOptions.find(x => x.name === newUserLeader);
      
      await addDoc(collection(db, "users"), {
        id: userIdCounter?.count + 1,
        name: newUserName,
        email: newUserEmail,
        phoneNumber: newUserPhoneNumber ? newUserPhoneNumber : '',
        personalId: newUserPersonalId ? newUserPersonalId : '',
        company: newUserCompany.toUpperCase(),
        dateOfEntry: newUserDateOfEntry,
        leader: newUserLeader,
        leaderEmail: newLeaderObject.email,
        status: "Pendiente",
        holidaysTaken: 0,
        holidaysLeft: 15,
        currentPeriod: (new Date().getFullYear()).toString(),
        role: newUserRole,
        accountantName: newUserAccountantName,
        accountantEmail: newUserAccountantEmail
      });

      const counterRef = query(collection(db, "counters"), where("name", "==", "userId"));
      const findCounters = await getDocs(counterRef);
      findCounters.forEach( async (counter) => {
        const getCounter = doc(db, 'counters', counter.id);
        await updateDoc(getCounter, {
          count: (userIdCounter?.count + 1)
        });
      });
      toggleAddUserModal();
      setNewUserName('');
      setNewUserEmail('');
      setNewUserPhoneNumber('');
      setNewUserCompany();
      setNewUserDateOfEntry('');
      setNewUserLeader();
      setNewUserRole();

      fetchMembers(userData);
      fetchUserIdCounter()
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  }

  const deleteUser = async () => {
    Swal.fire({
      text: "¿Estas segur@ que quieres eliminar este usuario?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#CCA449',
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar"
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);

        const userRef = query(collection(db, "users"), where("id", "==", employeeForModalId));
        const findUsers = await getDocs(userRef);
        findUsers.forEach( async (userObject) => {
          const getUser = doc(db, 'users', userObject.id);
          await deleteDoc(getUser);
        });

        toggleEmployeeModal();

        fetchMembers(userData);

        setIsLoading(false);
      }
    })
  }

  const getNumberOfDays = () => {
    if (userData?.dateOfEntry){
      var d1 = new Date();
      var d2 = new Date(`${userData?.dateOfEntry?.split('-')[1]}.${userData?.dateOfEntry?.split('-')[2].split('T')[0]}.${parseInt(userData?.dateOfEntry?.split('-')[0]) + ((new Date().getFullYear()) - (new Date(userData?.dateOfEntry).getFullYear())) + ((new Date().getTime()) > (new Date(new Date(userData?.dateOfEntry).setFullYear(2023)).getTime()) ? 1 : 0 )}`);
      return (d2-d1)/(1000*3600*24);
    }
  }

  const [createRequestModalIsVisible, setCreateRequestModalIsVisible] = useState(false);
  const toggleCreateRequestModal = () => {
    setCreateRequestModalIsVisible(!createRequestModalIsVisible);
  }

  if (isLoading) return (
    <div className='loadingPage'>
      <BarLoader color="#CCA449" />
    </div>
  )

  const hrDashboard = () => {
    const columns = [
      { field: 'id', headerName: 'ID', flex: 1, minWidth: 75, maxWidth: 90 },
      { field: 'name', headerName: 'Nombre', flex: 1, minWidth: 150},
      { field: 'company', headerName: 'Empresa', flex: 1, minWidth: 100 },
      { field: 'dateOfEntry', headerName: 'Ingreso', type: 'date', valueFormatter: params => new Date(params.value).toLocaleDateString('en-GB'), flex: 1, minWidth: 100 },
      { field: 'leader', headerName: 'Lider', flex: 1, minWidth: 150 },
      { field: 'status', headerName: 'Estatus', flex: 1, minWidth: 100, maxWidth: 120 },
      { field: 'holidaysTaken', headerName: 'Dias tomados', type: 'number', flex: 1, minWidth: 125 },
      { field: 'currentPeriod', headerName: 'Periodo actual', type: 'number', flex: 1, minWidth: 125 },
      { field: 'holidaysLeft', headerName: 'Dias por tomar', type: 'number', flex: 1, minWidth: 125 },
      // { field: 'firstName', headerName: 'First name', width: 130 },
      // { field: 'lastName', headerName: 'Last name', width: 130 },
      // {
      //   field: 'age',
      //   headerName: 'Age',
      //   type: 'number',
      //   width: 90,
      // },
      // {
      //   field: 'fullName',
      //   headerName: 'Full name',
      //   description: 'This column has a value getter and is not sortable.',
      //   sortable: false,
      //   width: 160,
      //   valueGetter: (params) =>
      //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
      // },
    ];

    // const data = [
    //   ["Tipo", "Dias", { role: "style" }],
    //   ["Incapacidad", 1, "#000000"],
    //   ["Permisos", 2, "#000000"],
    //   ["Vacación", 1, "#000000"],
    // ];

    return (
      <div>
        <div className='hrNavigationCardsContainer'>
          <a href='/requests' className='navigationCard'>
            <PiHandBold />
            <p>
              Solicitudes
            </p>
          </a>
          <div onClick={toggleAddUserModal} className='navigationCard'>
            <PiUserCirclePlusBold />
            <p>
              Agregar usuario
            </p>
          </div>
          <Modal id='newUserModal' isOpen={addUserModal} toggle={toggleAddUserModal} size={'md'}>        
            <ModalBody>
              <div className='modalHeader' style={{marginBottom: '1rem'}}>
                <div></div>
						    <IoClose onClick={toggleAddUserModal} />
					    </div>
              <div>
                <h3>
                  Nuevo usuario
                </h3>
                <div>
                  <p className='label'>
                    Nombre
                  </p>
                  <Input
                    type="text"
                    className="mainInput"
                    value={newUserName}
                    onChange={(e) => setNewUserName(e.target.value)}
                    placeholder="El nombre del nuevo usuario"
                  />
                  <p className='label'>
                    Correo
                  </p>
                  <Input
                    type="text"
                    className="mainInput"
                    value={newUserEmail}
                    onChange={(e) => setNewUserEmail(e.target.value)}
                    placeholder="El correo del nuevo usuario"
                  />
                  <p className='label'>
                    Telefono
                  </p>
                  <Input
                    type="text"
                    className="mainInput"
                    value={newUserPhoneNumber}
                    onChange={(e) => setNewUserPhoneNumber(e.target.value)}
                    placeholder="El telefono del nuevo usuario"
                  />
                  {/* <p className='label'>
                    DUI
                  </p>
                  <Input
                    type="text"
                    className="mainInput"
                    value={newUserPersonalId}
                    onChange={(e) => setNewUserPersonalId(e.target.value)}
                    placeholder="El DUI del nuevo usuario"
                  /> */}
                  <p className='label'>
                    Empresa
                  </p>
                  {/* <Input
                    type="text"
                    className="mainInput"
                    value={newUserCompany}
                    onChange={(e) => setNewUserCompany(e.target.value)}
                    placeholder="La empresa del nuevo usuario"
                  /> */}
                  <Input
                    type="select"
                    className="mainInput"
                    value={newUserCompany}
                    onChange={(e) => setNewUserCompany(e.target.value)}
                    defaultValue={"default"}
                  >
                    <option disabled value={"default"}>La empresa del nuevo usuario</option>
                    {
                      companyOptions?.map(company => {
                        return (
                          <option value={company.name}>{company.name}</option>
                        )
                      })
                    }
                  </Input>
                  <p className='label'>
                    Ingreso
                  </p>
                  <DatePicker
                    id="example-datepicker"
                    className='mainInput'
                    style={{borderRadius: 10}}
                    showClearButton={false}
                    value={newUserDateOfEntry} 
                    onChange={(v) => setNewUserDateOfEntry(v)}
                    dateFormat="DD.MM.YYYY"
                    placeholder="La fecha de ingreso del nuevo usuario"
                  />
                  <p className='label'>
                    Lider
                  </p>
                  {/* <Input
                    type="text"
                    className="mainInput"
                    value={newUserLeader}
                    onChange={(e) => setNewUserLeader(e.target.value)}
                    placeholder="El lider del nuevo usuario"
                  /> */}
                  <Input
                    type="select"
                    className="mainInput"
                    value={newUserLeader}
                    onChange={(e) => setNewUserLeader(e.target.value)}
                    defaultValue={"default"}
                  >
                    <option disabled value={"default"}>El lider del nuevo usuario</option>
                    {
                      leaderOptions?.map(leader => {
                        return (
                          <option value={leader.name}>{leader.name}</option>
                        )
                      })
                    }
                  </Input>
                  <p className='label'>
                    Nivel
                  </p>
                  <Input
                    type="select"
                    className="mainInput"
                    value={newUserRole}
                    onChange={(e) => setNewUserRole(e.target.value)}
                    defaultValue={"default"}
                  >
                    <option disabled value={"default"}>El nivel del nuevo usuario</option>
                    <option value={"employee"}>Empleado</option>
                    <option value={"leader"}>Lider</option>
                    <option value={"hr"}>Recursos Humanos</option>
                  </Input>
                  <p className='label'>
                    Nombre del pagador
                  </p>
                  <Input
                    type="text"
                    className="mainInput"
                    value={newUserAccountantName}
                    onChange={(e) => setNewUserAccountantName(e.target.value)}
                    placeholder="El nombre del pagador responsable de la planilla del nuevo usuario"
                  />
                  <p className='label'>
                    Correo del pagador
                  </p>
                  <Input
                    type="text"
                    className="mainInput"
                    value={newUserAccountantEmail}
                    onChange={(e) => setNewUserAccountantEmail(e.target.value)}
                    placeholder="El correo del pagador responsable de la planilla del nuevo usuario"
                  />
                  <Button onClick={createUser} disabled={!newUserName || !newUserEmail || !newUserCompany || !newUserDateOfEntry || !newUserLeader || !newUserAccountantName || !newUserAccountantEmail} className='primaryBtn' style={{marginTop: '1rem'}}>
                    Crear
                  </Button>
                </div>
              </div>
				    </ModalBody>
			    </Modal>
        </div>
        <a href='/createRequest' style={{textDecoration: 'none'}}>
        <div onClick={toggleCreateRequestModal} className='navigationCard' style={{marginBottom: '1rem'}}>
          <PiUserCirclePlusBold />
          <p>
            Crear solicitud
          </p>
        </div>
        </a>        
        <DataGrid
          getRowId={(row) => row.id}
          rows={members}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 20, 30]}
          onRowClick={handleEvent}
          checkboxSelection={false}
        />
        <Modal id='employeeModal' isOpen={employeeModal} toggle={toggleEmployeeModal} size={'md'}>        
        <ModalBody>
          <div className='modalHeader' style={{marginBottom: '1rem'}}>
            <div></div>
						<IoClose onClick={toggleEmployeeModal} />
					</div>
          <div>
            <h3>
              Detalle
              <Button onClick={deleteUser} className='primaryBtn' style={{width: 'fit-content', float: 'right'}}>
                Eliminar
              </Button>
            </h3>
            <div>
              <p className='label'>
                ID
              </p>
              <Input
                type="text"
                className="mainInput"
                value={employeeForModalId}
                onChange={(e) => setEmployeeForModalId(e.target.value)}
                disabled
              />
              <p className='label'>
                Nombre
              </p>
              <Input
                type="text"
                className="mainInput"
                value={employeeForModalName}
                onChange={(e) => setEmployeeForModalName(e.target.value)}
                placeholder="El nombre del usuario"
                disabled
              />
              <p className='label'>
                Correo
              </p>
              <Input
                type="text"
                className="mainInput"
                value={employeeForModalEmail}
                onChange={(e) => setEmployeeForModalEmail(e.target.value)}
                placeholder="El correo del usuario"
              />
              <p className='label'>
                Empresa
              </p>
              <Input
                type="text"
                className="mainInput"
                value={employeeForModalCompany}
                onChange={(e) => setEmployeeForModalCompany(e.target.value)}
                placeholder="La empresa del usuario"
              />
              <p className='label'>
                Ingreso
              </p>
              <DatePicker
                id="example-datepicker"
                className='mainInput'
                style={{borderRadius: 10}}
                showClearButton={false}
                value={employeeForModalDateOfEntry} 
                onChange={(v) => setEmployeeForModalDateOfEntry(v)}
                dateFormat="DD.MM.YYYY"
                placeholder="La fecha de ingreso del usuario"
              />
              {/* <Input
                type="text"
                className="mainInput"
                value={new Date(employeeForModalDateOfEntry).toLocaleDateString('en-GB')}
                onChange={(e) => setEmployeeForModalDateOfEntry(e.target.value)}
                placeholder="La fecha de ingreso del usuario"
                disabled
              /> */}
              <p className='label'>
                Lider
              </p>
              {/* <Input
                type="text"
                className="mainInput"
                value={employeeForModalLeader}
                onChange={(e) => setEmployeeForModalLeader(e.target.value)}
                placeholder="El lider del usuario"
              /> */}
              <Input
                type="select"
                className="mainInput"
                value={employeeForModalLeader}
                onChange={(e) => setEmployeeForModalLeader(e.target.value)}
                defaultValue={"default"}
              >
                <option disabled value={"default"}>El lider del usuario</option>
                {
                  leaderOptions?.map(leader => {
                    return (
                      <option value={leader.name}>{leader.name}</option>
                    )
                  })
                }
              </Input>
              <p className='label'>
                Estatus
              </p>
              <Input
                type="select"
                className="mainInput"
                value={employeeForModalStatus}
                onChange={(e) => setEmployeeForModalStatus(e.target.value)}
              >
                <option value={"Activo"}>Activo</option>
                <option value={"Inactivo"}>Inactivo</option>
                <option value={"Pendiente"}>Pendiente</option>
              </Input>
              <p className='label'>
                Dias tomados
              </p>
              <Input
                type="number"
                min={0}
                max={15}
                className="mainInput"
                value={employeeForModalHolidaysTaken}
                onChange={(e) => setEmployeeForModalHolidaysTaken(e.target.value)}
                placeholder="Los permisos tomados por el usuario"
              />
              <p className='label'>
                Periodo actual
              </p>
              <Input
                type="number"
                className="mainInput"
                value={employeeForModalCurrentPeriod}
                onChange={(e) => setEmployeeForModalCurrentPeriod(e.target.value)}
                placeholder="Los permisos tomados por el usuario"
              />
              <p className='label'>
                Dias por tomar
              </p>
              <Input
                type="number"
                min={0}
                max={15}
                className="mainInput"
                value={employeeForModalHolidaysLeft}
                onChange={(e) => setEmployeeForModalHolidaysLeft(e.target.value)}
                placeholder="Los permisos restantes del usuario"
              />
              <p className='label'>
                Nombre del pagador
              </p>
              <Input
                type="text"
                className="mainInput"
                value={employeeForModalAccountantName}
                onChange={(e) => setEmployeeForModalAccountantName(e.target.value)}
                placeholder="El nombre del pagador responsable de la planilla del usuario"
              />
              <p className='label'>
                Correo del pagador
              </p>
              <Input
                type="text"
                className="mainInput"
                value={employeeForModalAccountantEmail}
                onChange={(e) => setEmployeeForModalAccountantEmail(e.target.value)}
                placeholder="El correo del pagador responsable de la planilla del usuario"
              />
            </div>
            {/* TODO: Implement real data charts */}
            <Chart chartType="ColumnChart" width="100%" height="" data={graphData} options={{legend: 'none'}} />
            <div>
              {
                employeeForModalRequests?.length > 0 ?
                  <p className='label'>
                    Solicitudes
                  </p>
                  : null
              }
              {
                employeeForModalRequests?.map(request => {
                  return (
                    <div className='employeeForModalRequestContainer'>
                      <p>
                        {request.startDate !== '-' ? new Date(request.startDate).toLocaleDateString('de-DE') : new Date(request.date).toLocaleDateString('de-DE')}
                      </p>
                      <p>
                        {request.type}
                      </p>
                      <p>
                        {request.status}
                      </p>
                    </div>
                  )
                })
              }
            </div>
            <Button onClick={changeEmployeeData} className='primaryBtn' style={{marginTop: '1rem'}}>
              Guardar
            </Button>
          </div>
				</ModalBody>
			</Modal>
      </div>
    )
  }

  const employeeDashboard = () => {

    return (
      <div>
        <div style={{display: 'flex', flexDirection: 'row', marginBottom: '0.5rem'}}>
          <div style={{marginRight: '2rem'}}>
          <p className='label'>
            Nombre
          </p>
          <p className='value'>
            {userData?.name}
          </p>
          </div>
          <div style={{marginRight: '2rem'}}>
          <p className='label'>
            Fecha de finalizacion
          </p>
          <p className='value'>
            {userData?.dateOfEntry?.split('-')[2].split('T')[0]}.{userData?.dateOfEntry?.split('-')[1]}.{parseInt(userData?.dateOfEntry?.split('-')[0]) + ((new Date().getFullYear()) - (new Date(userData?.dateOfEntry).getFullYear())) + ((new Date().getTime()) > (new Date(new Date(userData?.dateOfEntry).setFullYear(2023)).getTime()) ? 1 : 0 )}
          </p>
          </div>
          <div style={{marginRight: '2rem'}}>
          <p className='label'>
            Dias para fecha de finalizacion
          </p>
          <p className='value'>
            {getNumberOfDays()?.toFixed(0)}
          </p>
          </div>
          <div>
          <p className='label'>
            Dias disponibles
          </p>
          <p className='value'>
            {userData?.holidaysLeft}
          </p>
          </div>
        </div>
        <div className='navigationCardsContainer'>
          <a href='/createRequest' className='navigationCard'>
            <PiPlusCircleBold />
            <p>
              Solicitar permiso
            </p>
          </a>
          {
            userData?.role === 'leader' ?
              <a href='/requests' className='navigationCard' style={{marginTop: '0.5rem'}}>
                <PiHandBold />
                <p>
                  Solicitudes
                </p>
              </a>
              : null
          }
        </div>
        <div style={{display: 'flex', flexDirection: 'row'}}>
        <p style={{width: '20%', padding: '1rem 1.5rem', paddingTop: 0, margin: 0}}>
          Inicio
        </p>
        <p style={{width: '20%', padding: '1rem 1.5rem', paddingTop: 0, margin: 0}}>
          Tiempo
        </p>
        <p style={{width: '20%', padding: '1rem 1.5rem', paddingTop: 0, margin: 0}}>
          Tipo
        </p>
        <p style={{width: '20%', padding: '1rem 1.5rem', paddingTop: 0, margin: 0}}>
          Nivel
        </p>
        <p style={{width: '20%', padding: '1rem 1.5rem', paddingTop: 0, margin: 0}}>
          Status
        </p>
      </div>
      {
        requests?.length > 0 ?
        requests?.map(request => {
          return (
            <div onClick={() => toggleRequestModal(request)} className='requestsListItem'>
              {
                !(request?.type === 'Permiso') ?
                <p className='requestsListNumberOfDays'>
                  {request?.startDate?.split('-')[2].split('T')[0]}.{request?.startDate?.split('-')[1]}.{request?.startDate?.split('-')[0]}
                </p>
                :
                <p className='requestsListNumberOfDays'>
                  -
                </p>
              }
                {
                  request?.type === 'Permiso' ?
                  <p className='requestsListNumberOfDays'>
                    {request?.hours} horas
                  </p>
                  :
                  <p className='requestsListNumberOfDays'>
                    {request?.numberOfDays} dias
                  </p>
                }
              <p className='requestsListType'>
                {request.type}
              </p>
              <p className='requestsListDegreeOfIncapacity'>
                {request?.degreeOfIncapacity !== '' ? request?.degreeOfIncapacity : "-"}
              </p> 
              <p className='requestsListStatus'>
                {request?.status}
              </p> 
            </div>
          )
        })
        :
        <p style={{textAlign: 'center', color: '#999999'}}>
          Aun no has hecho una solicitud
        </p>
      }
      <Modal id='requestModal' isOpen={requestModal} toggle={toggleRequestModal} size={'md'}>        
        <ModalBody>
          <div className='modalHeader' style={{marginBottom: '1rem'}}>
            <div></div>
						<IoClose onClick={toggleRequestModal} />
					</div>
          <div>
            <h3>
              Solicitud
            </h3>
            <div>
              <p className='label'>
                Nombre
              </p>
              <p className='value'>
                {selectedRequest?.employeeName}
              </p>
              <p className='label'>
                Fecha inicio
              </p>
              {
                !(selectedRequest?.type === 'Permiso') ?
                <p className='value'>
                  {selectedRequest?.startDate?.split('-')[2].split('T')[0]}.{selectedRequest?.startDate?.split('-')[1]}.{selectedRequest?.startDate?.split('-')[0]}
                </p>
                :
                <p className='value'>
                  -
                </p>
              }
              <p className='label'>
                Fecha final
              </p>
              {
                !(selectedRequest?.type === 'Permiso') ?
                <p className='value'>
                  {selectedRequest?.endDate?.split('-')[2].split('T')[0]}.{selectedRequest?.endDate?.split('-')[1]}.{selectedRequest?.endDate?.split('-')[0]}
                </p>
                :
                <p className='value'>
                  -
                </p>
              }  
              <p className='label'>
                Dias a tomar
              </p>
              <p className='value'>
                {selectedRequest?.numberOfDays}
              </p>
              <p className='label'>
                Tipo
              </p>
              <p className='value'>
                {selectedRequest?.type}
              </p>
              <p className='label'>
                Nivel
              </p>
              <p className='value'>
                {selectedRequest?.degreeOfIncapacity !== '' ? selectedRequest?.degreeOfIncapacity : "-"}
              </p>
              <p className='label'>
                Razon
              </p>
              <p className='value'>
                {selectedRequest?.reason}
              </p>
              <p className='label'>
                Fecha
              </p>
              <p className='value'>
                {selectedRequest?.date}
              </p>
              <p className='label'>
                Horas
              </p>
              <p className='value'>
                {selectedRequest?.hours}
              </p>
              <p className='label'>
                Status
              </p>
              <p className='value'>
                {selectedRequest?.status}
              </p>
            </div>
          </div>
				</ModalBody>
			</Modal>
      </div>
    )
  }

  return (
    <div id="dashboardPage" className='page'> 
      {
        ((userData?.role === 'hr') || (userData?.role === 'admin')) ?
        hrDashboard()
        : employeeDashboard()
      }
    </div>
  );
}

export default DashboardPage;