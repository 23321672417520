import { useState, useEffect } from 'react'

import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../util/firebase";
import { query, collection, getDocs, where, updateDoc, doc, addDoc } from "firebase/firestore";

import { useNavigate } from "react-router-dom";

import {DatePicker} from 'reactstrap-date-picker'
import { Modal, ModalBody, Button, Input } from 'reactstrap';
import BarLoader from "react-spinners/BarLoader";

import { MdChevronLeft } from 'react-icons/md';
import { IoClose } from 'react-icons/io5';

function RequestsPage() {
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false)

  const [user] = useAuthState(auth);

  const navigate = useNavigate();

  const [userRole, setUserRole] = useState();
  const [userName, setUserName] = useState();
  const fetchUserData = async () => {
    try {
      setIsLoading(true);
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setUserRole(data.role);
      setUserName(data.name);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line
  }, [user]);

  const [phoneNumberChangeRequests, setPhoneNumberChangeRequests] = useState();
  const fetchPhoneNumberChangeRequests = async () => {
    try {
      setIsLoading(true);
      let fetchedPhoneNumberChangeRequests = [];
      const phoneNumberChangeRequestsRef = collection(db, "phoneNumberChangeRequests");
      const q = query(phoneNumberChangeRequestsRef, where("status", "==", "pending"))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const newPhoneNumberChangeRequest = doc.data()
        fetchedPhoneNumberChangeRequests.push(newPhoneNumberChangeRequest)
      });

      setPhoneNumberChangeRequests(fetchedPhoneNumberChangeRequests);

      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching phone number change requests");
    }
  };

  useEffect(() => {
    fetchPhoneNumberChangeRequests();
    // eslint-disable-next-line
  }, []);

  const [activeRequestTypeFilter, setActiveRequestTypeFilter] = useState();
  const [requestTypeFilter, setRequestTypeFilter] = useState();
  const applyFilters = () => {
    setActiveRequestTypeFilter(requestTypeFilter);
    fetchRequests(requestTypeFilter);
  }
  const clearFilters = () => {
    setActiveRequestTypeFilter();
    setRequestTypeFilter("default");
    fetchRequests();
  }
  const [requesterNameFilter, setRequesterNameFilter] = useState();

  const [requests, setRequests] = useState();
  const fetchRequests = async (filterType) => {
    console.log(filterType)
    try {
      setIsLoading(true);

      let fetchedRequests = [];
      const requestsRef = collection(db, "requests");
      const q = query(requestsRef)
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const newRequest = doc.data();

        // if ((newRequest.status !== 'Denegada') && (newRequest.status !== 'Aprobada')){
          if (filterType){
            if (filterType === 'Vacacion'){
              if (newRequest.type === 'Vacacion'){
                fetchedRequests.push(newRequest)
              }
            } else if (filterType === 'Permiso'){
              if (newRequest.type === 'Permiso'){
                fetchedRequests.push(newRequest)
              }
            } else if (filterType === 'Incapacidad'){
              if (newRequest.type === 'Incapacidad'){
                fetchedRequests.push(newRequest)
              }
            }
          } else {
            fetchedRequests.push(newRequest)
          }
        // }
      });

      fetchedRequests.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b.dateRequested) - new Date(a.dateRequested);
      });

      setRequests(fetchedRequests);

      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching requests");
    }
  };

  useEffect(() => {
    fetchRequests();
    // eslint-disable-next-line
  }, []);

  // const [requests, setRequests] = useState([
  //   {
  //     type: 'Incapacidad',
  //     employeeName: 'Diego Spranger',
  //     numberOfDays: 3,
  //     degreeOfIncapacity: 'Grave',
  //     reason: "Fractura de hombro derecho",
  //     status: "Aprovada",
  //     startDate: '2023-09-01T12:00:00.000Z',
  //     endDate: '2023-09-05T12:00:00.000Z',
  //     leaderChecked: true
  //   },
  //   {
  //     type: 'Permiso',
  //     employeeName: 'Nathalie Samour',
  //     numberOfDays: 2,
  //     degreeOfIncapacity: '',
  //     reason: 'Viaje',
  //     status: "Pendiente",
  //     startDate: '2023-09-02T12:00:00.000Z',
  //     endDate: '2023-09-10T12:00:00.000Z',
  //     leaderChecked: false
  //   }
  // ]);

  const [selectedPhoneChangeRequest, setSelectedPhoneChangeRequest] = useState();
  const [phoneChangeRequestModal, setPhoneChangeRequestModal] = useState(false);
  const togglePhoneChangeRequestModal = (request) => {
    if (!phoneChangeRequestModal){
      setSelectedPhoneChangeRequest(request)
      setPhoneChangeRequestModal(true);
    } else {
      setPhoneChangeRequestModal(false);
    }
  }

  const acceptPhoneChangeRequest = async () => {
    setIsLoading(true);

    try {
      const requestRef = query(collection(db, "phoneNumberChangeRequests"), where("userUid", "==", selectedPhoneChangeRequest?.userUid));
      const findRequests = await getDocs(requestRef);
      findRequests.forEach( async (request) => {
        const getRequest = doc(db, 'phoneNumberChangeRequests', request.id);
        await updateDoc(getRequest, {
          status: 'approved'
        });
      });
      fetchPhoneNumberChangeRequests();
      const userRef = query(collection(db, "users"), where("uid", "==", selectedPhoneChangeRequest?.userUid));
      const findUsers = await getDocs(userRef);
      findUsers.forEach( async (user) => {
        const getUser = doc(db, 'users', user.id);
        await updateDoc(getUser, {
          phoneNumber: selectedPhoneChangeRequest?.newUserPhoneNumber
        });
      });
      togglePhoneChangeRequestModal();
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
    
    setIsLoading(false);
  }

  const denyPhoneChangeRequest = async () => {
    setIsLoading(true);

    try {
      const requestRef = query(collection(db, "phoneNumberChangeRequests"), where("userUid", "==", selectedPhoneChangeRequest?.userUid));
      const findRequests = await getDocs(requestRef);
      findRequests.forEach( async (request) => {
        const getRequest = doc(db, 'phoneNumberChangeRequests', request.id);
        await updateDoc(getRequest, {
          status: 'denied'
        });
      });
      fetchPhoneNumberChangeRequests();
      togglePhoneChangeRequestModal();
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
    
    setIsLoading(false);
  }

  const [selectedRequest, setSelectedRequest] = useState();
  const [selectedRequestStartDate, setSelectedRequestStartDate] = useState();
  const [selectedRequestEndDate, setSelectedRequestEndDate] = useState();
  const [selectedRequestStatus, setSelectedRequestStatus] = useState();
  const [requestModal, setRequestModal] = useState(false);
  const toggleRequestModal = (request) => {
    if (!requestModal){
      setSelectedRequest(request)
      setSelectedRequestStartDate(request.startDate)
      setSelectedRequestEndDate(request.endDate)
      setSelectedRequestStatus(request.status)
      setRequestModal(true);
    } else {
      setRequestModal(false);
    }
  }

  const getNumberOfDays = (startDate, endDate) => {
    if (startDate && endDate){
      var d1 = new Date(startDate);
      var d2 = new Date(endDate);
      return (((d2-d1)/(1000*3600*24)) + 1);
    }
  }

  const changeRequestData = async () => {
    setIsLoading(true);

    try {
      const requestRef = query(collection(db, "requests"), where("uid", "==", selectedRequest?.uid));
      const findRequests = await getDocs(requestRef);
      findRequests.forEach( async (request) => {
        const getRequest = doc(db, 'requests', request.id);
        await updateDoc(getRequest, {
          startDate: selectedRequestStartDate,
          endDate: selectedRequestEndDate,
          status: selectedRequestStatus
        });

        if (selectedRequest !== selectedRequest.status){
          if (selectedRequest.type === 'Vacacion' || selectedRequest.type === 'Permiso'){
            if (selectedRequestStatus === 'Aceptada'){
              await addDoc(collection(db, "mail"), {
                to: [
                  'camila.guzman@gmaritimo.com'
                  // 'sprangerventures@gmail.com'
                ],
                message: {
                  subject: "Una solicitud ha sido actualizada",
                  html: `Hola <b>Camila</b>,<br/>
                    <br/>
                    La solicitud de <b>${selectedRequest.employeeName}</b> ha sido <b>aceptada</b>:<br/>
                    <b>Nombre del solicitante: </b>${selectedRequest.employeeName}<br/>
                    ${
                      selectedRequest.type === 'Permiso' ?
                        `
                        <b>Fecha: </b>${selectedRequest.date ? (new Date(selectedRequest.date).toLocaleDateString("de-DE")) : "-"}<br/>
                        <b>Numero de horas: </b>${selectedRequest.hours ? selectedRequest.hours : "-"}
                        `
                      :
                        `
                        <b>Fecha de inicio: </b>${selectedRequest.startDate ? (new Date(selectedRequest.startDate).toLocaleDateString("de-DE")) : '-'}<br/>
                        <b>Fecha final: </b>${selectedRequest.endDate ? (new Date(selectedRequest.endDate).toLocaleDateString("de-DE")) : '-'}<br/>
                        <b>Numero de dias: </b>${getNumberOfDays(selectedRequest.startDate, selectedRequest.endDate) ? getNumberOfDays(selectedRequest.startDate, selectedRequest.endDate) : '-'}<br/>
                        <br/>
                        Informacion de referencia:<br/>
                        <b>Dias gozados (periodo ${selectedRequest.currentPeriod} - ${(parseInt(selectedRequest.currentPeriod)) + 1}): </b>${selectedRequest.holidaysTaken}<br/>
                        `
                    }
                    <br/>
                    Ingresa a la app para aprobar o denegar su solicitud <a href="https://vacaciones.grupomaritimo.com/" target="_blank">aqui</a><br/>
                    <br/>
                    Cualquier duda o comentario favor comunicarse al correo camila.guzman@gmaritimo.com`
                }
              });
            } else if (selectedRequestStatus === 'Rechazada'){
              await addDoc(collection(db, "mail"), {
                to: [
                  'camila.guzman@gmaritimo.com'
                  // 'sprangerventures@gmail.com'
                ],
                message: {
                  subject: "Una solicitud ha sido actualizada",
                  html: `Hola <b>Camila</b>,<br/>
                    <br/>
                    La solicitud de <b>${selectedRequest.employeeName}</b> ha sido <b>rechazada</b>:<br/>
                    <b>Nombre del solicitante: </b>${selectedRequest.employeeName}<br/>
                    ${
                      selectedRequest.type === 'Permiso' ?
                        `
                        <b>Fecha: </b>${selectedRequest.date ? (new Date(selectedRequest.date).toLocaleDateString("de-DE")) : "-"}<br/>
                        <b>Numero de horas: </b>${selectedRequest.hours ? selectedRequest.hours : "-"}
                        `
                      :
                        `
                        <b>Fecha de inicio: </b>${selectedRequest.startDate ? (new Date(selectedRequest.startDate).toLocaleDateString("de-DE")) : '-'}<br/>
                        <b>Fecha final: </b>${selectedRequest.endDate ? (new Date(selectedRequest.endDate).toLocaleDateString("de-DE")) : '-'}<br/>
                        <b>Numero de dias: </b>${getNumberOfDays(selectedRequest.startDate, selectedRequest.endDate) ? getNumberOfDays(selectedRequest.startDate, selectedRequest.endDate) : '-'}<br/>
                        <br/>
                        Informacion de referencia:<br/>
                        <b>Dias gozados (periodo ${selectedRequest.currentPeriod} - ${(parseInt(selectedRequest.currentPeriod)) + 1}): </b>${selectedRequest.holidaysTaken}<br/>
                        `
                    }
                    <br/>
                    Ingresa a la app para aprobar o denegar su solicitud <a href="https://vacaciones.grupomaritimo.com/" target="_blank">aqui</a><br/>
                    <br/>
                    Cualquier duda o comentario favor comunicarse al correo camila.guzman@gmaritimo.com`
                }
              });
              // await addDoc(collection(db, "mail"), {
              //   to: [
              //     selectedRequest.employeeEmail
              //     // 'sprangerventures@gmail.com'
              //   ],
              //   message: {
              //     subject: "Tu solicitud ha sido actualizada",
              //     html: `Hola <b>${selectedRequest.employeeName}</b>,<br/>
              //       <br/>
              //       <b>Tu solicitud ha sido <b>rechazada</b>:<br/>
              //       <b>Nombre del solicitante: </b>${selectedRequest.employeeName}<br/>
              //       ${
              //         selectedRequest === 'Permiso' ?
              //           `
              //           <b>Fecha: </b>${selectedRequest.date ? (new Date(selectedRequest.date).toLocaleDateString("de-DE")) : "-"}<br/>
              //           <b>Numero de horas: </b>${selectedRequest.hours ? selectedRequest.hours : "-"}
              //           `
              //         :
              //           `
              //           <b>Fecha de inicio: </b>${selectedRequest.startDate ? (new Date(selectedRequest.startDate).toLocaleDateString("de-DE")) : '-'}<br/>
              //           <b>Fecha final: </b>${selectedRequest.endDate ? (new Date(selectedRequest.endDate).toLocaleDateString("de-DE")) : '-'}<br/>
              //           <b>Numero de dias: </b>${getNumberOfDays(selectedRequest.startDate, selectedRequest.endDate) ? getNumberOfDays(selectedRequest.startDate, selectedRequest.endDate) : '-'}
              //           `
              //       }<br/>
              //       <br/>
              //       Cualquier duda o comentario favor comunicarse al correo camila.guzman@gmaritimo.com`
              //   }
              // });
            } else if (selectedRequestStatus === 'Aprobada'){
              if (selectedRequest.type !== 'Permiso'){
              const userRef = query(collection(db, "users"), where("uid", "==", selectedRequest?.employeeUid));
              const findUsers = await getDocs(userRef);
              const data = findUsers.docs[0].data();
              findUsers.forEach( async (user) => {
                const getUser = doc(db, 'users', user.id);
                await updateDoc(getUser, {
                  holidaysLeft: parseInt(data.holidaysLeft) - parseInt(getNumberOfDays(selectedRequest.startDate, selectedRequest.endDate)),
                  holidaysTaken: parseInt(data.holidaysTaken) + parseInt(getNumberOfDays(selectedRequest.startDate, selectedRequest.endDate)),
                });
              });
              }
              await addDoc(collection(db, "mail"), {
                to: [
                  selectedRequest.leaderEmail
                  // 'sprangerventures@gmail.com'
                ],
                message: {
                  subject: "Una solicitud ha sido actualizada",
                  html: `Hola <b>${selectedRequest.leaderResponsible}</b>,<br/>
                    <br/>
                    La solicitud de <b>${selectedRequest.employeeName}</b> ha sido <b>aprobada</b>:<br/>
                    <b>Nombre del solicitante: </b>${selectedRequest.employeeName}<br/>
                    ${
                      selectedRequest.type === 'Permiso' ?
                        `
                        <b>Fecha: </b>${selectedRequest.date ? (new Date(selectedRequest.date).toLocaleDateString("de-DE")) : "-"}<br/>
                        <b>Numero de horas: </b>${selectedRequest.hours ? selectedRequest.hours : "-"}
                        `
                      :
                        `
                        <b>Fecha de inicio: </b>${selectedRequest.startDate ? (new Date(selectedRequest.startDate).toLocaleDateString("de-DE")) : '-'}<br/>
                        <b>Fecha final: </b>${selectedRequest.endDate ? (new Date(selectedRequest.endDate).toLocaleDateString("de-DE")) : '-'}<br/>
                        <b>Numero de dias: </b>${getNumberOfDays(selectedRequest.startDate, selectedRequest.endDate) ? getNumberOfDays(selectedRequest.startDate, selectedRequest.endDate) : '-'}<br/>
                        <br/>
                        Informacion de referencia:<br/>
                        <b>Dias gozados (periodo ${selectedRequest.currentPeriod} - ${(parseInt(selectedRequest.currentPeriod)) + 1}): </b>${selectedRequest.holidaysTaken}<br/>
                        `
                    }
                    <br/>
                    Cualquier duda o comentario favor comunicarse al correo camila.guzman@gmaritimo.com`
                }
              });
              await addDoc(collection(db, "mail"), {
                to: [
                  selectedRequest.accountantEmail
                  // 'sprangerventures@gmail.com'
                ],
                message: {
                  subject: "Una solicitud ha sido actualizada",
                  html: `Hola <b>${selectedRequest.accountantName}</b>,<br/>
                    <br/>
                    La solicitud de <b>${selectedRequest.employeeName}</b> ha sido <b>aprobada</b>:<br/>
                    <b>Nombre del solicitante: </b>${selectedRequest.employeeName}<br/>
                    ${
                      selectedRequest.type === 'Permiso' ?
                        `
                        <b>Fecha: </b>${selectedRequest.date ? (new Date(selectedRequest.date).toLocaleDateString("de-DE")) : "-"}<br/>
                        <b>Numero de horas: </b>${selectedRequest.hours ? selectedRequest.hours : "-"}
                        `
                      :
                        `
                        <b>Fecha de inicio: </b>${selectedRequest.startDate ? (new Date(selectedRequest.startDate).toLocaleDateString("de-DE")) : '-'}<br/>
                        <b>Fecha final: </b>${selectedRequest.endDate ? (new Date(selectedRequest.endDate).toLocaleDateString("de-DE")) : '-'}<br/>
                        <b>Numero de dias: </b>${getNumberOfDays(selectedRequest.startDate, selectedRequest.endDate) ? getNumberOfDays(selectedRequest.startDate, selectedRequest.endDate) : '-'}<br/>
                        <br/>
                        Informacion de referencia:<br/>
                        <b>Dias gozados (periodo ${selectedRequest.currentPeriod} - ${(parseInt(selectedRequest.currentPeriod)) + 1}): </b>${selectedRequest.holidaysTaken}<br/>
                        `
                    }
                    <br/>
                    Cualquier duda o comentario favor comunicarse al correo camila.guzman@gmaritimo.com`
                }
              });
              await addDoc(collection(db, "mail"), {
                to: [
                  selectedRequest.employeeEmail
                  // 'sprangerventures@gmail.com'
                ],
                message: {
                  subject: `Tu solicitud de ${selectedRequest.type.toLowerCase()} ha sido actualizada`,
                  html: `Hola <b>${selectedRequest.employeeName}</b>,<br/>
                    <br/>
                    Tu solicitud ha sido <b>aprobada</b>:<br/>
                    <b>Nombre del solicitante: </b>${selectedRequest.employeeName}<br/>
                    ${
                      selectedRequest.type === 'Permiso' ?
                        `
                        <b>Fecha: </b>${selectedRequest.date ? (new Date(selectedRequest.date).toLocaleDateString("de-DE")) : "-"}<br/>
                        <b>Numero de horas: </b>${selectedRequest.hours ? selectedRequest.hours : "-"}
                        `
                      :
                        `
                        <b>Fecha de inicio: </b>${selectedRequest.startDate ? (new Date(selectedRequest.startDate).toLocaleDateString("de-DE")) : '-'}<br/>
                        <b>Fecha final: </b>${selectedRequest.endDate ? (new Date(selectedRequest.endDate).toLocaleDateString("de-DE")) : '-'}<br/>
                        <b>Numero de dias: </b>${getNumberOfDays(selectedRequest.startDate, selectedRequest.endDate) ? getNumberOfDays(selectedRequest.startDate, selectedRequest.endDate) : '-'}<br/>
                        <br/>
                        Informacion de referencia:<br/>
                        <b>Dias gozados (periodo ${selectedRequest.currentPeriod} - ${(parseInt(selectedRequest.currentPeriod)) + 1}): </b>${selectedRequest.holidaysTaken}<br/>
                        `
                    }
                    <br/>
                    <b>NO OLVIDAR ACTIVAR O.O.O. BAJO NINGUNA CIRCUNSTANCIA</b>
                    <br/>
                    Cualquier duda o comentario favor comunicarse al correo camila.guzman@gmaritimo.com`
                }
              });
            } else if (selectedRequestStatus === 'Denegada'){
              await addDoc(collection(db, "mail"), {
                to: [
                  selectedRequest.leaderEmail
                  // 'sprangerventures@gmail.com'
                ],
                message: {
                  subject: "Una solicitud ha sido actualizada",
                  html: `Hola <b>${selectedRequest.leaderResponsible}</b>,<br/>
                    <br/>
                    La solicitud de <b>${selectedRequest.employeeName}</b> ha sido <b>denegada</b>:<br/>
                    <b>Nombre del solicitante: </b>${selectedRequest.employeeName}<br/>
                    ${
                      selectedRequest.type === 'Permiso' ?
                        `
                        <b>Fecha: </b>${selectedRequest.date ? (new Date(selectedRequest.date).toLocaleDateString("de-DE")) : "-"}<br/>
                        <b>Numero de horas: </b>${selectedRequest.hours ? selectedRequest.hours : "-"}
                        `
                      :
                        `
                        <b>Fecha de inicio: </b>${selectedRequest.startDate ? (new Date(selectedRequest.startDate).toLocaleDateString("de-DE")) : '-'}<br/>
                        <b>Fecha final: </b>${selectedRequest.endDate ? (new Date(selectedRequest.endDate).toLocaleDateString("de-DE")) : '-'}<br/>
                        <b>Numero de dias: </b>${getNumberOfDays(selectedRequest.startDate, selectedRequest.endDate) ? getNumberOfDays(selectedRequest.startDate, selectedRequest.endDate) : '-'}<br/>
                        <br/>
                        Informacion de referencia:<br/>
                        <b>Dias gozados (periodo ${selectedRequest.currentPeriod} - ${(parseInt(selectedRequest.currentPeriod)) + 1}): </b>${selectedRequest.holidaysTaken}<br/>
                        `
                    }
                    <br/>
                    Cualquier duda o comentario favor comunicarse al correo camila.guzman@gmaritimo.com`
                }
              });
              await addDoc(collection(db, "mail"), {
                to: [
                  selectedRequest.employeeEmail
                  // 'sprangerventures@gmail.com'
                ],
                message: {
                  subject: `Tu solicitud de ${selectedRequest.type.toLowerCase()} ha sido actualizada`,
                  html: `Hola <b>${selectedRequest.employeeName}</b>,<br/>
                    <br/>
                    Tu solicitud ha sido <b>denegada</b>:<br/>
                    <b>Nombre del solicitante: </b>${selectedRequest.employeeName}<br/>
                    ${
                      selectedRequest.type === 'Permiso' ?
                        `
                        <b>Fecha: </b>${selectedRequest.date ? (new Date(selectedRequest.date).toLocaleDateString("de-DE")) : "-"}<br/>
                        <b>Numero de horas: </b>${selectedRequest.hours ? selectedRequest.hours : "-"}
                        `
                      :
                        `
                        <b>Fecha de inicio: </b>${selectedRequest.startDate ? (new Date(selectedRequest.startDate).toLocaleDateString("de-DE")) : '-'}<br/>
                        <b>Fecha final: </b>${selectedRequest.endDate ? (new Date(selectedRequest.endDate).toLocaleDateString("de-DE")) : '-'}<br/>
                        <b>Numero de dias: </b>${getNumberOfDays(selectedRequest.startDate, selectedRequest.endDate) ? getNumberOfDays(selectedRequest.startDate, selectedRequest.endDate) : '-'}<br/>
                        <br/>
                        Informacion de referencia:<br/>
                        <b>Dias gozados (periodo ${selectedRequest.currentPeriod} - ${(parseInt(selectedRequest.currentPeriod)) + 1}): </b>${selectedRequest.holidaysTaken}<br/>
                        `
                    }
                    <br/>
                    Cualquier duda o comentario favor comunicarse al correo camila.guzman@gmaritimo.com`
                }
              });
            }
          }
        }

      });
      fetchRequests();
      toggleRequestModal();
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
    
    setIsLoading(false);
  }

  const handleStartDateChange = (value) => {
    setSelectedRequestStartDate(value)
  }

  const handleEndDateChange = (value) => {
    setSelectedRequestEndDate(value)
  }

  const [reasonForCancellation, setReasonForCancellation] = useState();

  const [revertRequestModal, setRevertRequestModal] = useState(false);
  const toggleRevertRequestModal = () => {
    setRevertRequestModal(!revertRequestModal);
  }

  const revertRequest = async () => {
    setIsLoading(true);

    try {
      const userRef = query(collection(db, "users"), where("uid", "==", selectedRequest?.employeeUid));
      const findUsers = await getDocs(userRef);
      const data = findUsers.docs[0].data();
      if (selectedRequestStatus === 'Aprobada'){
      findUsers.forEach( async (user) => {
        const getUser = doc(db, 'users', user.id);
        await updateDoc(getUser, {
          holidaysLeft: parseInt(data.holidaysLeft) + parseInt(selectedRequest.numberOfDays),
          holidaysTaken: parseInt(data.holidaysTaken) - parseInt(selectedRequest.numberOfDays),
        });
      });
      }
      const requestRef = query(collection(db, "requests"), where("uid", "==", selectedRequest?.uid));
      const findRequests = await getDocs(requestRef);
      findRequests.forEach( async (request) => {
        const getRequest = doc(db, 'requests', request.id);
        // await deleteDoc(getRequest);
        await updateDoc(getRequest, {
          status: 'Cancelada',
          reasonForCancellation: reasonForCancellation
        });
      });
      await addDoc(collection(db, "mail"), {
        to: [
          'camila.guzman@gmaritimo.com'
          // 'sprangerventures@gmail.com'
        ],
        message: {
          subject: "Una solicitud ha sido revertida",
          html: `Hola <b>Camila</b>,<br/>
            <br/>
            La solicitud de <b>${selectedRequest.employeeName}</b> ha sido <b>revertida</b> porque <b>${selectedRequest.reasonForCancellation}</b>:<br/>
            <b>Nombre del solicitante: </b>${selectedRequest.employeeName}<br/>
            ${
              selectedRequest.type === 'Permiso' ?
                `
                <b>Fecha: </b>${selectedRequest.date ? (new Date(selectedRequest.date).toLocaleDateString("de-DE")) : "-"}<br/>
                <b>Numero de horas: </b>${selectedRequest.hours ? selectedRequest.hours : "-"}
                `
              :
                `
                <b>Fecha de inicio: </b>${selectedRequest.startDate ? (new Date(selectedRequest.startDate).toLocaleDateString("de-DE")) : '-'}<br/>
                <b>Fecha final: </b>${selectedRequest.endDate ? (new Date(selectedRequest.endDate).toLocaleDateString("de-DE")) : '-'}<br/>
                <b>Numero de dias: </b>${getNumberOfDays(selectedRequest.startDate, selectedRequest.endDate) ? getNumberOfDays(selectedRequest.startDate, selectedRequest.endDate) : '-'}<br/>
                <br/>
                Informacion de referencia:<br/>
                <b>Dias gozados (periodo ${selectedRequest.currentPeriod} - ${(parseInt(selectedRequest.currentPeriod)) + 1}): </b>${selectedRequest.holidaysTaken}<br/>
                `
            }
            <br/>
            Cualquier duda o comentario favor comunicarse al correo camila.guzman@gmaritimo.com`
        }
      });

      await addDoc(collection(db, "mail"), {
        to: [
          selectedRequest.leaderEmail
        ],
        message: {
          subject: "Una solicitud ha sido revertida",
          html: `Hola <b>${selectedRequest.leaderResponsible}</b>,<br/>
            <br/>
            La solicitud de <b>${selectedRequest.employeeName}</b> ha sido <b>revertida</b> porque <b>${selectedRequest.reasonForCancellation}</b>:<br/>
            <b>Nombre del solicitante: </b>${selectedRequest.employeeName}<br/>
            ${
              selectedRequest.type === 'Permiso' ?
                `
                <b>Fecha: </b>${selectedRequest.date ? (new Date(selectedRequest.date).toLocaleDateString("de-DE")) : "-"}<br/>
                <b>Numero de horas: </b>${selectedRequest.hours ? selectedRequest.hours : "-"}
                `
              :
                `
                <b>Fecha de inicio: </b>${selectedRequest.startDate ? (new Date(selectedRequest.startDate).toLocaleDateString("de-DE")) : '-'}<br/>
                <b>Fecha final: </b>${selectedRequest.endDate ? (new Date(selectedRequest.endDate).toLocaleDateString("de-DE")) : '-'}<br/>
                <b>Numero de dias: </b>${getNumberOfDays(selectedRequest.startDate, selectedRequest.endDate) ? getNumberOfDays(selectedRequest.startDate, selectedRequest.endDate) : '-'}<br/>
                <br/>
                Informacion de referencia:<br/>
                <b>Dias gozados (periodo ${selectedRequest.currentPeriod} - ${(parseInt(selectedRequest.currentPeriod)) + 1}): </b>${selectedRequest.holidaysTaken}<br/>
                `
            }
            <br/>
            Cualquier duda o comentario favor comunicarse al correo camila.guzman@gmaritimo.com`
        }
      });
      setReasonForCancellation('');
      setRevertRequestModal(false);
      fetchRequests();
      toggleRequestModal();
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
    
    setIsLoading(false);
  }

  if (isLoading) return (
    <div className='loadingPage'>
      <BarLoader color="#CCA449" />
    </div>
  )

  return (
    <div id="requestsPage" className='page'> 
      <h1>
        <MdChevronLeft onClick={() => navigate(-1)} />
        Solicitudes
      </h1>
      {
        phoneNumberChangeRequests?.length > 0 ?
        <div style={{marginBottom: '2rem'}}>
          <h4>
            Cambio de numero de telefono
          </h4>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <p style={{width: '33.3%', padding: '1rem 1.5rem', paddingTop: 0, margin: 0}}>
              Nombre
            </p>
            <p style={{width: '33.3%', padding: '1rem 1.5rem', paddingTop: 0, margin: 0}}>
              Numero actual
            </p>
            <p style={{width: '33.3%', padding: '1rem 1.5rem', paddingTop: 0, margin: 0}}>
              Numero nuevo
            </p>
          </div>
          {
            phoneNumberChangeRequests?.map(request => {
              return (
                <div onClick={() => togglePhoneChangeRequestModal(request)} className='phoneChangeRequestsListItem'>
                  <p>
                    {request.userName}
                  </p>
                  <p>
                    {request.oldUserPhoneNumber}
                  </p>
                  <p>
                    {request.newUserPhoneNumber}
                  </p>
                </div>
              )
            })
          }
          <Modal id='phoneChangeRequestModal' isOpen={phoneChangeRequestModal} toggle={togglePhoneChangeRequestModal} size={'md'}>
            <ModalBody>
              <div className='modalHeader' style={{marginBottom: '1rem'}}>
                <div></div>
						    <IoClose onClick={togglePhoneChangeRequestModal} />
					    </div>
              <div>
                <h3>
                  Solicitud
                </h3>
                <div>
                  <p className='label'>
                    Nombre
                  </p>
                  <p className='value'>
                    {selectedPhoneChangeRequest?.userName}
                  </p>
                  <p className='label'>
                    Numero actual
                  </p>
                  <p className='value'>
                    {selectedPhoneChangeRequest?.oldUserPhoneNumber}
                  </p>
                  <p className='label'>
                    Numero nuevo
                  </p>
                  <p className='value'>
                    {selectedPhoneChangeRequest?.newUserPhoneNumber}
                  </p>
                </div>
                <Button onClick={acceptPhoneChangeRequest} className='primaryBtn' style={{marginBottom: '0.5rem', marginTop: '1rem'}}>
                  Aceptar
                </Button>
                <Button onClick={denyPhoneChangeRequest} className='secondaryBtn'>
                  Denegar
                </Button>
              </div>
				    </ModalBody>
			    </Modal>
        </div>
        :
        null
      }
      <h4>
        Permisos e incapacidades
      </h4>
      <div style={{marginBottom: '1rem'}}>
        <p style={{marginBottom: 0, fontWeight: 'bold'}}>
          Filtros
        </p>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '0.5rem'}}>
          <div style={{width: '100%'}}>
          <p className='label'>
            Tipo
          </p>
          <Input
            type="select"
            className="mainInput"
            value={requestTypeFilter}
            onChange={(e) => setRequestTypeFilter(e.target.value)}
            defaultValue={"default"}
            style={{marginBottom: 0}}
          >
            <option disabled value={"default"}>El tipo de solicitud</option>
            <option value={"Vacacion"}>Vacacion</option>
            <option value={"Incapacidad"}>Incapacidad</option>
            <option value={"Permiso"}>Permiso</option>
          </Input>
          </div>
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
          <Button onClick={applyFilters} className='primaryBtn' style={{width: 'fit-content', paddingLeft: '2rem', paddingRight: '2rem', marginLeft: '1rem'}}>
            Aplicar
          </Button>
          </div>
          {
            activeRequestTypeFilter ?
              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
                <Button onClick={clearFilters} className='secondaryBtn' style={{width: 'fit-content', paddingLeft: '2rem', paddingRight: '2rem', marginLeft: '0.5rem'}}>
                  Limpiar
                </Button>
              </div>
              : null
          }
        </div>
        <p className='label'>
          Nombre
        </p>
        <Input
          type="text"
          className="mainInput"
          value={requesterNameFilter}
          onChange={(e) => setRequesterNameFilter(e.target.value)}
          style={{marginBottom: 0}}
          placeholder='El nombre del solicitante'
        />
      </div>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <p style={{width: '20%', padding: '1rem 1.5rem', paddingTop: 0, margin: 0}}>
          Nombre
        </p>
        <p style={{width: '20%', padding: '1rem 1.5rem', paddingTop: 0, margin: 0}}>
          Fecha creada
        </p>
        <p style={{width: '20%', padding: '1rem 1.5rem', paddingTop: 0, margin: 0}}>
          Tiempo
        </p>
        <p style={{width: '20%', padding: '1rem 1.5rem', paddingTop: 0, margin: 0}}>
          Tipo
        </p>
        <p style={{width: '20%', padding: '1rem 1.5rem', paddingTop: 0, margin: 0}}>
          Nivel
        </p>
      </div>
      {
        userRole === 'leader' ?
        // eslint-disable-next-line
        requests?.map(request => {
          if (userName === request.leaderResponsible){
            if (requesterNameFilter){
              if (request.employeeName.toLowerCase().includes(requesterNameFilter.toLowerCase())){
                return (
                  <div onClick={() => toggleRequestModal(request)} className='requestsListItem'>
                    <p className='requestsListEmployeeName'>
                      {request.employeeName}
                    </p>
                    <p className='requestsListNumberOfDays'>
                      {request?.dateRequested?.split('-')[2].split('T')[0]}.{request?.dateRequested?.split('-')[1]}.{request?.dateRequested?.split('-')[0]}
                    </p>
                    {
                        request?.type === 'Permiso' ?
                        <p className='requestsListNumberOfDays'>
                          {request?.hours} horas
                        </p>
                        :
                        <p className='requestsListNumberOfDays'>
                          {request?.numberOfDays} dias
                        </p>
                      }
                    <p className='requestsListType'>
                      {request.type}
                    </p>
                    <p className='requestsListDegreeOfIncapacity'>
                      {request?.degreeOfIncapacity !== '' ? request?.degreeOfIncapacity : "-"}
                    </p> 
                  </div>
                )
              }
            } else {
              return (
                <div onClick={() => toggleRequestModal(request)} className='requestsListItem'>
                  <p className='requestsListEmployeeName'>
                    {request.employeeName}
                  </p>
                  <p className='requestsListNumberOfDays'>
                    {request?.dateRequested?.split('-')[2].split('T')[0]}.{request?.dateRequested?.split('-')[1]}.{request?.dateRequested?.split('-')[0]}
                  </p>
                  {
                      request?.type === 'Permiso' ?
                      <p className='requestsListNumberOfDays'>
                        {request?.hours} horas
                      </p>
                      :
                      <p className='requestsListNumberOfDays'>
                        {request?.numberOfDays} dias
                      </p>
                    }
                  <p className='requestsListType'>
                    {request.type}
                  </p>
                  <p className='requestsListDegreeOfIncapacity'>
                    {request?.degreeOfIncapacity !== '' ? request?.degreeOfIncapacity : "-"}
                  </p> 
                </div>
              )
            }
          }
        })
          :
          // eslint-disable-next-line
        requests?.map(request => {
          if (requesterNameFilter){
            if (request.employeeName.toLowerCase().includes(requesterNameFilter.toLowerCase())){
              return (
                <div onClick={() => toggleRequestModal(request)} className='requestsListItem'>
                  <p className='requestsListEmployeeName'>
                    {request.employeeName}
                  </p>
                  <p className='requestsListNumberOfDays'>
                    {request?.dateRequested?.split('-')[2].split('T')[0]}.{request?.dateRequested?.split('-')[1]}.{request?.dateRequested?.split('-')[0]}
                  </p>
                  {
                      request?.type === 'Permiso' ?
                      <p className='requestsListNumberOfDays'>
                        {request?.hours} horas
                      </p>
                      :
                      <p className='requestsListNumberOfDays'>
                        {request?.numberOfDays} dias
                      </p>
                    }
                  <p className='requestsListType'>
                    {request.type}
                  </p>
                  <p className='requestsListDegreeOfIncapacity'>
                    {request?.degreeOfIncapacity !== '' ? request?.degreeOfIncapacity : "-"}
                  </p> 
                </div>
              )
            }
          } else {
            return (
              <div onClick={() => toggleRequestModal(request)} className='requestsListItem'>
                <p className='requestsListEmployeeName'>
                  {request.employeeName}
                </p>
                <p className='requestsListNumberOfDays'>
                  {request?.dateRequested?.split('-')[2].split('T')[0]}.{request?.dateRequested?.split('-')[1]}.{request?.dateRequested?.split('-')[0]}
                </p>
                {
                    request?.type === 'Permiso' ?
                    <p className='requestsListNumberOfDays'>
                      {request?.hours} horas
                    </p>
                    :
                    <p className='requestsListNumberOfDays'>
                      {request?.numberOfDays} dias
                    </p>
                  }
                <p className='requestsListType'>
                  {request.type}
                </p>
                <p className='requestsListDegreeOfIncapacity'>
                  {request?.degreeOfIncapacity !== '' ? request?.degreeOfIncapacity : "-"}
                </p> 
              </div>
            )
          }
        })
      }
      <Modal id='requestModal' isOpen={requestModal} toggle={toggleRequestModal} size={'md'}>
        <ModalBody>
          <div className='modalHeader' style={{marginBottom: '1rem'}}>
            <div></div>
						<IoClose onClick={toggleRequestModal} />
					</div>
          <div>
            <h3>
              Solicitud
            </h3>
            <div>
              <p className='label'>
                Nombre
              </p>
              <p className='value'>
                {selectedRequest?.employeeName}
              </p>
              <p className='label'>
                Fecha creada
              </p>
              <p className='value'>
                {new Date(selectedRequest?.dateRequested).toLocaleDateString('de-DE')}
              </p>
              <p className='label'>
                Fecha inicio
              </p>
              {
                !(selectedRequest?.type === 'Permiso') ?
              <div>
              {
                ((userRole === 'hr') || (userRole === 'admin')) ?
                  <DatePicker
                    id="example-datepicker"
                    className='mainInput'
                    style={{borderRadius: 10}}
                    showClearButton={false}
                    value={selectedRequestStartDate} 
                    onChange={(v,f) => handleStartDateChange(v, f)}
                    dateFormat="DD.MM.YYYY"
                  />
                  :
                  <p className='value'>
                    {selectedRequest?.startDate.split('-')[2].split('T')[0]}.{selectedRequest?.startDate.split('-')[1]}.{selectedRequest?.startDate.split('-')[0]}
                  </p>
              }
              </div>
              :
                <p className='value'>
                  -
                </p>
              }
              <p className='label'>
                Fecha final
              </p>
              {
                !(selectedRequest?.type === 'Permiso') ?
              <div>
              {
                ((userRole === 'hr') || (userRole === 'admin')) ?
                  <DatePicker
                    id="example-datepicker"
                    className='mainInput'
                    style={{borderRadius: 10}}
                    showClearButton={false}
                    value={selectedRequestEndDate} 
                    onChange={(v,f) => handleEndDateChange(v, f)}
                    dateFormat="DD.MM.YYYY"
                  />
                  :
                  <p className='value'>
                    {selectedRequest?.endDate.split('-')[2].split('T')[0]}.{selectedRequest?.endDate.split('-')[1]}.{selectedRequest?.endDate.split('-')[0]}
                  </p>
              }
              </div>
                :
                <p className='value'>
                  -
                </p>
              }
              <p className='label'>
                Dias a tomar
              </p>
              <p className='value'>
                {selectedRequest?.numberOfDays}
              </p>
              <p className='label'>
                Tipo
              </p>
              <p className='value'>
                {selectedRequest?.type}
              </p>
              <p className='label'>
                Nivel
              </p>
              <p className='value'>
                {selectedRequest?.degreeOfIncapacity !== '' ? selectedRequest?.degreeOfIncapacity : "-"}
              </p>
              <p className='label'>
                Razon
              </p>
              <p className='value'>
                {selectedRequest?.reason ? selectedRequest?.reason : '-'}
              </p>
              <p className='label'>
                Fecha
              </p>
              <p className='value'>
                {(selectedRequest?.date && selectedRequest?.date !== '-') ? new Date(selectedRequest.date).toLocaleDateString('de-DE') : '-'}
              </p>
              <p className='label'>
                Horas
              </p>
              <p className='value'>
                {selectedRequest?.hours}
              </p>
              <p className='label'>
                Status
              </p>
              {
                (userRole === 'leader') ?
                  (selectedRequest?.status === 'Pendiente') ?
                    <Input
                      type="select"
                      className="mainInput"
                      value={selectedRequestStatus}
                      onChange={(e) => setSelectedRequestStatus(e.target.value)}
                    >
                      <option disabled value={"Pendiente"}>Pendiente</option>
                      <option value={"Rechazada"}>Rechazada</option>
                      <option value={"Aceptada"}>Aceptada</option>
                    </Input>
                  :
                    <p className='value'>
                      {selectedRequest?.status}
                    </p>
                :
                  (userRole === 'hr') ?
                    ((selectedRequest?.createdByType === 'leader') && (selectedRequest?.status === 'Pendiente')) ?
                      <Input
                        type="select"
                        className="mainInput"
                        value={selectedRequestStatus}
                        onChange={(e) => setSelectedRequestStatus(e.target.value)}
                      >
                        <option disabled value={"Pendiente"}>Pendiente</option>
                        <option value={"Denegada"}>Denegada</option>
                        <option value={"Aprobada"}>Aprobada</option>
                      </Input>
                    :
                      ((selectedRequest?.status === 'Rechazada') || (selectedRequest?.status === 'Aceptada')) ?
                        <Input
                          type="select"
                          className="mainInput"
                          value={selectedRequestStatus}
                          onChange={(e) => setSelectedRequestStatus(e.target.value)}
                        >
                          <option disabled value={"Rechazada"}>Rechazada</option>
                          <option disabled value={"Aceptada"}>Aceptada</option>
                          <option value={"Denegada"}>Denegada</option>
                          <option value={"Aprobada"}>Aprobada</option>
                        </Input>
                      :
                        <p className='value'>
                          {selectedRequest?.status}
                        </p>
                :
                  (userRole === 'admin') ?
                    <Input
                      type="select"
                      className="mainInput"
                      value={selectedRequestStatus}
                      onChange={(e) => setSelectedRequestStatus(e.target.value)}
                    >
                      <option value={"Pendiente"}>Pendiente</option>
                      <option value={"Rechazada"}>Rechazada</option>
                      <option value={"Aceptada"}>Aceptada</option>
                      <option value={"Denegada"}>Denegada</option>
                      <option value={"Aprobada"}>Aprobada</option>
                      <option value={"Cancelada"}>Cancelada</option>
                    </Input>
                  :
                    <p className='value'>
                      {selectedRequest?.status}
                    </p>
              }
              {
                selectedRequest?.status === 'Cancelada' ?
                <div>
                  <p className='label'>
                    Motivo de cancelacion
                  </p>
                  <p className='value'>
                    {selectedRequest?.reasonForCancellation}
                  </p>
                </div> : null
              }
              <Button onClick={changeRequestData} disabled={selectedRequest?.status === selectedRequestStatus && selectedRequest?.startDate === selectedRequestStartDate && selectedRequest?.endDate === selectedRequestEndDate} className='primaryBtn' style={{marginTop: '0.5rem'}}>
                Guardar
              </Button>
              {
                (((userRole === 'hr') || (userRole === 'admin')) && selectedRequest?.status === 'Aprobada' && selectedRequest?.type !== 'Incapacidad') ?
                <Button onClick={toggleRevertRequestModal} className='secondaryBtn' style={{marginTop: '0.5rem'}}>
                  Revertir
                </Button>
                : null
              }
              <Modal id='requestModal' isOpen={revertRequestModal} toggle={toggleRevertRequestModal} size={'md'}>
                <ModalBody>
                  <div className='modalHeader' style={{marginBottom: '1rem'}}>
                    <div></div>
						        <IoClose onClick={toggleRevertRequestModal} />
					        </div>
                  <div>
                    <h3>
                      Revertir solicitud
                    </h3>
                  </div>
                  <div>
                    <p className='label'>
                      Razon
                    </p>
                    <Input
                      type="text"
                      className="mainInput"
                      value={reasonForCancellation}
                      onChange={(e) => setReasonForCancellation(e.target.value)}
                      placeholder="La razon de porque se revierte esta solicitud"
                    />
                    <Button onClick={revertRequest} className='primaryBtn' style={{marginTop: '0.5rem'}}>
                      Revertir
                    </Button>
                  </div>
                </ModalBody>
			        </Modal>
            </div>
          </div>
				</ModalBody>
			</Modal>
    </div>
  );
}

export default RequestsPage;