import { useState, useEffect } from 'react';

import { db } from "../../util/firebase";
import { query, collection, getDocs, where, doc, updateDoc } from "firebase/firestore";

import { MdChevronLeft } from 'react-icons/md';
import { IoClose } from 'react-icons/io5';

import { useNavigate } from "react-router-dom";

import BarLoader from "react-spinners/BarLoader";
import { Button, Modal, ModalBody } from 'reactstrap';
import Swal from 'sweetalert2';

function AdminHRPage() {

  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const [companies, setCompanies] = useState();
  const fetchCompanies = async () => {
    try {
      setIsLoading(true);
  
      let fetchedCompanies = [];
      const companiesRef = collection(db, "companies");
      const q = query(companiesRef)
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const newCompany = doc.data()
        fetchedCompanies.push(newCompany)
      });
  
      setCompanies(fetchedCompanies);
  
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching companies");
    }
  }
  useEffect(() => {    
    fetchCompanies();
  }, []);

  const [hrEmployees, setHrEmployees] = useState();
  const fetchHrEmployees = async () => {
    try {
      setIsLoading(true);
  
      let fetchedUsers = [];
      const usersRef = collection(db, "users");
      const q = query(usersRef)
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const newUser = doc.data()
        if (newUser.role === 'hr'){
          fetchedUsers.push(newUser)
        }
      });
  
      setHrEmployees(fetchedUsers);
  
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching hr employees");
    }
  }
  useEffect(() => {    
    fetchHrEmployees();
  }, []);

  const [hrToEdit, setHrToEdit] = useState();
  const [hrModalIsVisible, setHrModalIsVisible] = useState(false);
  const toggleHrModal = (employee) => {
    if (employee){
      setHrToEdit(employee);
      setHrModalIsVisible(!hrModalIsVisible);
    } else {
      setHrToEdit();
      setHrModalIsVisible(!hrModalIsVisible);
    }
  }

  const [addToHrCoverageModalIsVisible, setAddToHrCoverageModalIsVisible] = useState();
  const toggleAddToHrCoverageModal = () => {
    setAddToHrCoverageModalIsVisible(!addToHrCoverageModalIsVisible);
  }

  const addCompanyToHrCoverage = async (company) => {
    Swal.fire({
      text: `¿Estas segur@ que quieres agregar esta empresa a la gestion de ${hrToEdit?.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#CCA449',
      confirmButtonText: "Si, agregar",
      cancelButtonText: "No, cancelar"
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);

        let newHrCoverage = [...hrToEdit.hrCoverage];
        newHrCoverage.push(company)
        
        try {
          const userRef = query(collection(db, "users"), where("uid", "==", hrToEdit.uid));
          const findUsers = await getDocs(userRef);
          findUsers.forEach( async (user) => {
            const getUser = doc(db, 'users', user.id);
            await updateDoc(getUser, {
              hrCoverage: newHrCoverage
            });
          });

          fetchHrEmployees();

          toggleHrModal();
          toggleAddToHrCoverageModal();

          Swal.fire({
            text: "Empresas a gestionar actualizadas exitosamente",
            icon: 'success', 
            confirmButtonColor: '#CCA449',
            confirmButtonText: "Okay",
          });

          setIsLoading(false);
        } catch (err) {
          console.error(err);
          alert("An error occured while adding to HR coverage");
          setIsLoading(false);
        }
      }
    });
  }

  const removeCompanyFromHrCoverage = async (company) => {
    Swal.fire({
      text: `¿Estas segur@ que quieres remover esta empresa a la gestion de ${hrToEdit?.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#CCA449',
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar"
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);

        let newHrCoverage = [...hrToEdit.hrCoverage];
        var index = newHrCoverage.indexOf(company);
        if (index !== -1) {
          newHrCoverage.splice(index, 1);
        }
        
        try {
          const userRef = query(collection(db, "users"), where("uid", "==", hrToEdit.uid));
          const findUsers = await getDocs(userRef);
          findUsers.forEach( async (user) => {
            const getUser = doc(db, 'users', user.id);
            await updateDoc(getUser, {
              hrCoverage: newHrCoverage
            });
          });

          fetchHrEmployees();

          toggleHrModal();

          Swal.fire({
            text: "Empresas a gestionar actualizadas exitosamente",
            icon: 'success', 
            confirmButtonColor: '#CCA449',
            confirmButtonText: "Okay",
          });

          setIsLoading(false);
        } catch (err) {
          console.error(err);
          alert("An error occured while adding to HR coverage");
          setIsLoading(false);
        }
      }
    });
  }

  if (isLoading) return (
    <div className='loadingPage'>
      <BarLoader color="#CCA449" />
    </div>
  )

  return (
    <div id="helpPage" className='page'> 
      <h1>
        <MdChevronLeft onClick={() => navigate(-1)} />
        Recursos humanos
      </h1>
      <div>
        {
          hrEmployees?.length > 0 ?
            hrEmployees.map(employee => {
              return (
                <div onClick={() => toggleHrModal(employee)} className='adminCompaniesCompany' style={{cursor: 'pointer'}}>
                  <p>
                    {employee.name}
                  </p>
                </div>
              )
            })
          :
            <p style={{textAlign: 'center'}}>
              Aun no hay empleados de recursos humanos
            </p>
        }
        <Modal id='hrModal' isOpen={hrModalIsVisible} toggle={toggleHrModal} size={'lg'}>
          <ModalBody>
            <div className='modalHeader' style={{marginBottom: '1rem'}}>
              <div></div>
						  <IoClose onClick={toggleHrModal} />
					  </div>
            <div>
              <h3>
                Empresas a gestionar
              </h3>
              <div>
                <Button onClick={toggleAddToHrCoverageModal} className='primaryBtn' style={{marginBottom: '1rem'}}>
                  Agregar empresa a gestionar
                </Button>
                {
                  hrToEdit?.hrCoverage?.length > 0 ?
                    hrToEdit.hrCoverage.map(company => {
                      return (
                        <div className='adminCompaniesCompany'>
                          <p>
                            {company}
                          </p>
                          <IoClose onClick={() => removeCompanyFromHrCoverage(company)}/>
                        </div>
                      )
                    })
                  :
                    <p>
                      Este empleado aun no gestiona ninguna empresa
                    </p>
                }
              </div>
            </div>
				  </ModalBody>
			  </Modal>
        <Modal id='addToHrCoverageModal' isOpen={addToHrCoverageModalIsVisible} toggle={toggleAddToHrCoverageModal} size={'lg'}>
          <ModalBody>
            <div className='modalHeader' style={{marginBottom: '1rem'}}>
              <div></div>
						  <IoClose onClick={toggleAddToHrCoverageModal} />
					  </div>
            <div>
              <h3>
                Agregar empresa a gestionar
              </h3>
              <div>
                <Button onClick={() => navigate('/admin/createCompany')} className='secondaryBtn' style={{marginBottom: '1rem'}}>Agregar empresa</Button>
                {
                  companies?.length > 0 ?
                  // eslint-disable-next-line
                    companies.map(company => {
                      if (!(hrToEdit?.hrCoverage?.includes(company.name))){
                        return (
                          <Button onClick={() => addCompanyToHrCoverage(company.name)} className='primaryBtn' style={{marginBottom: '0.5rem'}}>
                            {company.name}
                          </Button>
                        )
                      }
                    })
                  :
                    <p>
                      Aun no hay empresas para gestionar
                    </p>
                }
              </div>
            </div>
				  </ModalBody>
			  </Modal>
      </div>
    </div>
  );
}

export default AdminHRPage;