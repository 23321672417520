import { useState, useEffect } from 'react';

import Swal from 'sweetalert2'

import { db } from "../../util/firebase";
import { query, collection, getDocs, deleteDoc, where, doc } from "firebase/firestore";

import { useNavigate } from "react-router-dom";

import BarLoader from "react-spinners/BarLoader";
import { MdChevronLeft } from 'react-icons/md';
import { IoClose } from 'react-icons/io5';

import { Button } from 'reactstrap';

function AdminCompaniesPage() {

  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const [companies, setCompanies] = useState();
  const fetchCompanies = async () => {
    try {
      setIsLoading(true);
  
      let fetchedCompanies = [];
      const companiesRef = collection(db, "companies");
      const q = query(companiesRef)
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const newCompany = doc.data()
        fetchedCompanies.push(newCompany)
      });
  
      setCompanies(fetchedCompanies);
  
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching companies");
    }
  }
  useEffect(() => {    
    fetchCompanies();
  }, []);

  const deleteCompany = (companyName) => {
    Swal.fire({
      text: "¿Estas segur@ que quieres eliminar esta empresa?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#CCA449',
      confirmButtonText: "Si, eliminar",
      cancelButtonText: "No, cancelar"
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true)
        
        try {
          const companyRef = query(collection(db, "companies"), where("name", "==", companyName));
          const findCompanies = await getDocs(companyRef);
          findCompanies.forEach( async (company) => {
            const getCompany = doc(db, 'companies', company.id);
            await deleteDoc(getCompany);
          });

          fetchCompanies();

          Swal.fire({
            text: "Empresa eliminada exitosamente",
            icon: 'success', 
            confirmButtonColor: '#CCA449',
            confirmButtonText: "Okay",
          });

          setIsLoading(false);
        } catch (err) {
          console.error(err);
          alert("An error occured while deleting company");
          setIsLoading(false);
        }
      }
    })
  }

  if (isLoading) return (
    <div className='loadingPage'>
      <BarLoader color="#CCA449" />
    </div>
  )

  return (
    <div id="helpPage" className='page'> 
      <h1>
        <MdChevronLeft onClick={() => navigate(-1)} />
        Empresas
      </h1>
      <div>
        <Button onClick={() => navigate('/admin/createCompany')} className='primaryBtn' style={{marginBottom: '1rem'}}>
          Agregar empresa
        </Button>
        {
          companies?.length > 0 ?
            companies.map(company => {
              return (
                <div className='adminCompaniesCompany'>
                  <p>
                    {company.name}
                  </p>
                  <IoClose onClick={() => deleteCompany(company.name)}/>
                </div>
              )
            })
            :
            <p>
              Aun no hay empresas
            </p>
        }
      </div>
    </div>
  );
}

export default AdminCompaniesPage;