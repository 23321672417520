import { useState, useEffect, useRef, useCallback } from 'react'

import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../util/firebase";
import { query, collection, getDocs, where } from "firebase/firestore";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import classnames from 'classnames'

import BarLoader from "react-spinners/BarLoader";

import { Button, Modal, ModalBody, Input } from 'reactstrap';

import { IoClose } from 'react-icons/io5';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function ReportsPage() {
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);

  const [user] = useAuthState(auth);

  const [activeTab, setActiveTab] = useState(1);

  const [userData, setUserData] = useState();
  const fetchUserData = async () => {
    try {
      setIsLoading(true);
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setUserData(data);
      setIsLoading(false);
    } catch (err) {
      fetchUserData()
      // console.error(err);
      // alert("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line
  }, []);

  const perCompanyOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Cantidad de dias tomados por empresa',
      },
    },
  };

  const perEmployeeOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Cantidad de dias tomados por empleado',
      },
    },
  };

  const [perCompanyIncapacidades, setPerCompanyIncapacidades] = useState();
  const [perCompanyVacaciones, setPerCompanyVacaciones] = useState();
  const [perCompanyPermisos, setPerCompanyPermisos] = useState();
  const perCompanyData = {
    labels: ['ASERSA', 'REMAR', 'ABC', 'OPSAL', 'OTISA', 'TRANSMARES', 'TERMINALES PORTUARIAS', 'LOGINTER', 'M&R'],
    datasets: [
      {
        label: 'Incapacidades',
        data: perCompanyIncapacidades,
        backgroundColor: '#CCA449',
      },
      {
        label: 'Vacaciones',
        data: perCompanyVacaciones,
        backgroundColor: '#A48025',
      },
      {
        label: 'Permisos',
        data: perCompanyPermisos,
        backgroundColor: '#7D5E00',
      },
    ],
  };

  const [memberNames, setMemberNames] = useState();
  const [perMemberIncapacidades, setPerMemberIncapacidades] = useState();
  const [perMemberVacaciones, setPerMemberVacaciones] = useState();
  const [perMemberPermisos, setPerMemberPermisos] = useState();
  const perEmployeeData = {
    labels: memberNames,
    datasets: [
      {
        label: 'Incapacidades',
        data: perMemberIncapacidades,
        backgroundColor: '#CCA449',
      },
      {
        label: 'Vacaciones',
        data: perMemberVacaciones,
        backgroundColor: '#A48025',
      },
      {
        label: 'Permisos',
        data: perMemberPermisos,
        backgroundColor: '#7D5E00',
      },
    ],
  };

  const [activeMonthFilter, setActiveMonthFilter] = useState();
  const [filterMonth, setFilterMonth] = useState();
  const [filterYear, setFilterYear] = useState();

  // eslint-disable-next-line
  const [requests, setRequests] = useState();
  const fetchRequests = async (memberNames, monthFilter) => {
    try {
      setIsLoading(true);

      let newPerCompanyIncapacidades = [];
      let newPerCompanyVacaciones = [];
      let newPerCompanyPermisos = [];
      let newPerMemberIncapacidades = [];
      let newPerMemberVacaciones = [];
      let newPerMemberPermisos = [];

      let fetchedRequests = [];
      const requestsRef = collection(db, "requests");
      const q = query(requestsRef, where("status", "==", "Aprobada"))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const newRequest = doc.data()
        fetchedRequests.push(newRequest)
      });

      fetchedRequests.forEach(request => {
        const requestEmployee = request.employeeName;
        const requestCompany = request.employeeCompany;
        const requestType = request.type;

        const companiesArray = ['ASERSA', 'REMAR', 'ABC', 'OPSAL', 'OTISA', 'TRANSMARES', 'TERMINALES PORTUARIAS', 'LOGINTER', 'M&R'];
        const employeesArray = memberNames;

        const companiesArrayIndex = companiesArray.indexOf(requestCompany);
        const employeesArrayIndex = employeesArray.indexOf(requestEmployee);

        console.log(monthFilter);
        
        if (monthFilter){
          console.log('ACTIVE', request)
          if (requestType === 'Incapacidad'){
            const requestStartDate = (request.startDate.split('-')[0]+'-'+request.startDate.split('-')[1])
            // console.log(requestStartDate, activeMonthFilter);
            if (requestStartDate === monthFilter){
              newPerCompanyIncapacidades[companiesArrayIndex] ? newPerCompanyIncapacidades[companiesArrayIndex] += request.numberOfDays : newPerCompanyIncapacidades[companiesArrayIndex] = request.numberOfDays;
              newPerMemberIncapacidades[employeesArrayIndex] ? newPerMemberIncapacidades[employeesArrayIndex] += request.numberOfDays : newPerMemberIncapacidades[employeesArrayIndex] = request.numberOfDays;
            }
          } else if (requestType === 'Vacacion'){
            const requestStartDate = (request.startDate.split('-')[0]+'-'+request.startDate.split('-')[1])
            // console.log(requestStartDate, activeMonthFilter);
            if (requestStartDate === monthFilter){
              newPerCompanyVacaciones[companiesArrayIndex] ? newPerCompanyVacaciones[companiesArrayIndex] += request.numberOfDays : newPerCompanyVacaciones[companiesArrayIndex] = request.numberOfDays;
              newPerMemberVacaciones[employeesArrayIndex] ? newPerMemberVacaciones[employeesArrayIndex] += request.numberOfDays : newPerMemberVacaciones[employeesArrayIndex] = request.numberOfDays;
            }
          } else if (requestType === 'Permiso'){
            const requestDate = (request.date.split('-')[0]+'-'+request.date.split('-')[1])
            // console.log(requestDate, activeMonthFilter);
            if (requestDate === monthFilter){
              newPerCompanyPermisos[companiesArrayIndex] += parseFloat('0.' + request.hours);
              newPerMemberPermisos[employeesArrayIndex] += parseFloat('0.' + request.hours);
            }
          }
        } else {
          console.log('INACTIVE', request)
          if (requestType === 'Incapacidad'){
            newPerCompanyIncapacidades[companiesArrayIndex] ? newPerCompanyIncapacidades[companiesArrayIndex] += request.numberOfDays : newPerCompanyIncapacidades[companiesArrayIndex] = request.numberOfDays;
            newPerMemberIncapacidades[employeesArrayIndex] ? newPerMemberIncapacidades[employeesArrayIndex] += request.numberOfDays : newPerMemberIncapacidades[employeesArrayIndex] = request.numberOfDays;
          } else if (requestType === 'Vacacion'){
            newPerCompanyVacaciones[companiesArrayIndex] ? newPerCompanyVacaciones[companiesArrayIndex] += request.numberOfDays : newPerCompanyVacaciones[companiesArrayIndex] = request.numberOfDays;
            newPerMemberVacaciones[employeesArrayIndex] ? newPerMemberVacaciones[employeesArrayIndex] += request.numberOfDays : newPerMemberVacaciones[employeesArrayIndex] = request.numberOfDays;
          } else if (requestType === 'Permiso'){
            newPerCompanyPermisos[companiesArrayIndex] += parseFloat('0.' + request.hours);
            newPerMemberPermisos[employeesArrayIndex] += parseFloat('0.' + request.hours);
          }
        }
      })

      // console.log(newPerCompanyIncapacidades);
      // console.log(newPerCompanyVacaciones);
      
      setPerCompanyIncapacidades(newPerCompanyIncapacidades);
      setPerCompanyVacaciones(newPerCompanyVacaciones);
      setPerCompanyPermisos(newPerCompanyPermisos);
      setPerMemberIncapacidades(newPerMemberIncapacidades);
      setPerMemberVacaciones(newPerMemberVacaciones);
      setPerMemberPermisos(newPerMemberPermisos);

      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching requests");
    }
  };

  const fetchMembers = async (userData) => {
    try {
      setIsLoading(true);
  
      let fetchedMemberNames = [];
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("email", "!=", userData?.email))
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const newUser = doc.data()
        fetchedMemberNames.push(newUser.name);
      });
  
      setMemberNames(fetchedMemberNames);
      fetchRequests(fetchedMemberNames);
  
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching members");
    }
  };

  useEffect(() => {
    if (userData){
      fetchMembers(userData);
    }
    // eslint-disable-next-line 
  }, [userData]);

  // useEffect(() => {
  //   if (memberNames){
  //     fetchRequests(memberNames, null);
  //   }

  //   // eslint-disable-next-line
  // }, []);

  const [isFilteringByMonth, setIsFilteringByMonth] = useState(false);
  const toggleIsFilteringByMonth = () => {
    setIsFilteringByMonth(!isFilteringByMonth)
  }
  const applyMonthFilter = () => {
    setActiveMonthFilter(filterYear+'-'+filterMonth);
    fetchRequests(memberNames, (filterYear+'-'+filterMonth))
    toggleIsFilteringByMonth();
  }
  const clearMonthFilter = () => {
    setActiveMonthFilter();
    setFilterMonth('');
    setFilterYear('');
    fetchRequests(memberNames, null);
  }

  let perCompanyRef = useRef(null);
  const downloadPerCompanyImage = useCallback(() => {
    const link = document.createElement("a");
    link.download = "chart.png";
    link.href = perCompanyRef.current.toBase64Image();
    link.click();
  }, [])

  let perMemberRef = useRef(null);
  const downloadPerMemberImage = useCallback(() => {
    const link = document.createElement("a");
    link.download = "chart.png";
    link.href = perMemberRef.current.toBase64Image();
    link.click();
  }, [])

  if (isLoading) return (
    <div className='loadingPage'>
      <BarLoader color="#CCA449" />
    </div>
  )

  return (
    <div id="helpPage" className='page'> 
      <h1>
        Reportes
      </h1>
      <div>
        <div className='helpPageButtonContainer'>
          <Button onClick={() => setActiveTab(1)} className={classnames(
            "left",
            {
              active: activeTab === 1
            }
          )}>
            Por empresa
          </Button>
          <Button onClick={() => setActiveTab(2)} className={classnames(
            "right",
            {
              active: activeTab === 2
            }
          )}>
            Por empleado
          </Button>
        </div>
        <div style={{marginBottom: '1rem'}}>
          <Button onClick={toggleIsFilteringByMonth} className='secondaryBtn' style={{marginBottom: '0.5rem'}}>
            Filtros
          </Button>
          {activeMonthFilter ?
            <p>
              Filtrando por mes
              <IoClose onClick={clearMonthFilter} style={{width: 20, height: 20, color: '#CCA449', marginTop: -2.5, cursor: 'pointer'}}/>
            </p>
            : null
          }
          <Modal id='changeProfilePictureModal' isOpen={isFilteringByMonth} toggle={toggleIsFilteringByMonth} size={'md'}>
          <ModalBody>
          <div className='modalHeader' style={{marginBottom: '1rem'}}>
            <div></div>
						<IoClose onClick={toggleIsFilteringByMonth} />
					</div>
          <div>
            <h3>
              Filtros
            </h3>
            <p className='label'>
              Mes
            </p>
            <Input
              type="select"
              className="mainInput"
              value={filterMonth}
              onChange={(e) => setFilterMonth(e.target.value)}
              defaultValue={''}
            >
              <option value=''>El mes por el cual filtrar</option>
              <option value='01'>Enero</option>
              <option value='02'>Febrero</option>
              <option value='03'>Marzo</option>
              <option value='04'>Abril</option>
              <option value='05'>Mayo</option>
              <option value='06'>Junio</option>
              <option value='07'>Julio</option>
              <option value='08'>Agosto</option>
              <option value='09'>Septiembre</option>
              <option value='10'>Octubre</option>
              <option value='11'>Noviembr</option>
              <option value='12'>Diciembre</option>
            </Input>
            <p className='label'>
              Año
            </p>
            <Input
              type="select"
              className="mainInput"
              value={filterYear}
              onChange={(e) => setFilterYear(e.target.value)}
              defaultValue={''}
            >
              <option value=''>El año por el cual filtrar</option>
              <option value='2023'>2023</option>
            </Input>
            <Button onClick={applyMonthFilter} disabled={!(filterMonth && filterYear)} className='primaryBtn' style={{marginTop: '1rem'}}>
              Filtrar
            </Button>
            <Button onClick={clearMonthFilter} className='secondaryBtn' style={{marginTop: '0.5rem'}}>
              Limpiar
            </Button>
          </div>
				  </ModalBody>
			  </Modal>
        </div>
        {
          activeTab === 1 ?
            <div>
              <Bar options={perCompanyOptions} data={perCompanyData} ref={perCompanyRef} />
              <Button onClick={downloadPerCompanyImage} className='primaryBtn' style={{marginTop: '1rem'}}>
                Descargar
              </Button>
            </div>
            :
            <div>
              <Bar options={perEmployeeOptions} data={perEmployeeData} ref={perMemberRef} />
              <Button onClick={downloadPerMemberImage} className='primaryBtn' style={{marginTop: '1rem'}}>
                Descargar
              </Button>
            </div>
        }
      </div>
    </div>
  );
}

export default ReportsPage;