import { useState, useEffect } from 'react'

import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../util/firebase";
import { query, collection, getDocs, where, addDoc, doc, updateDoc } from "firebase/firestore";

// import emailjs from "@emailjs/browser";

import {v4 as uuidv4} from 'uuid';

import { useNavigate } from "react-router-dom";

import BarLoader from "react-spinners/BarLoader";

import { MdChevronLeft } from 'react-icons/md';

import {DatePicker} from 'reactstrap-date-picker'
import { Input, Button } from 'reactstrap';

function CreateRequestPage() {
  const [isLoading, setIsLoading] = useState(false)

  const [user] = useAuthState(auth);

  const navigate = useNavigate();

  const [userData, setUserData] = useState();
  const fetchUserData = async () => {
    try {
      setIsLoading(true);

      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setUserData(data);
      
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line
  }, [user]);

  const [type, setType] = useState();
  const [level, setLevel] = useState();
  const [reason, setReason] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [permitDate, setPermitDate] = useState();
  const [permitHours, setPermitHours] = useState();

  const [selectedEmployee, setSelectedEmployee] = useState();
  const [employees, setEmployees] = useState();
  const fetchEmployees = async () => {
    try {
      setIsLoading(true);
  
      let fetchedMembers = [];
      const usersRef = collection(db, "users");
      const q = query(usersRef)
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const newUser = doc.data()
        fetchedMembers.push(newUser)
      });
  
      setEmployees(fetchedMembers);
  
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching members");
    }
  }
  useEffect(() => {    
    fetchEmployees();
  }, []);

  const getNumberOfDays = () => {
    if (startDate && endDate){
      var d1 = new Date(startDate);
      var d2 = new Date(endDate);
      return (((d2-d1)/(1000*3600*24)) + 1);
    }
  }

  function isWeekend(date1, date2) {
    var d1 = new Date(date1),
        d2 = new Date(date2), 
        isWeekend = false;

    while (d1 <= d2) {
      var day = d1.getDay();
      isWeekend = (day === 6) || (day === 0);
      if (isWeekend) { return true; }
      d1.setDate(d1.getDate() + 1);
    }
    return false;
  }

  const createRequest = async () => {
    let selectedEmployeeObject = employees.find(obj => {
      return obj.name === selectedEmployee
    })

    const accountantEmail = userData?.role === 'hr' ? selectedEmployeeObject.accountantEmail : userData.accountantEmail;
    if (!accountantEmail){
      alert('Disculpa, no puedes crear una solicitud sin un pagador asignado a la cuenta');
      return
    }

    const employeeForRequest = userData?.role === 'hr' ? selectedEmployeeObject : userData
    if (type === 'Permiso'){
      if (permitHours > 4){
        alert('Disculpa, no puedes solicitar mas de 4 horas de permiso');
        return
      } else if (permitHours < 0){
        alert('Disculpa, no puedes solicitar horas negativas de permiso');
        return
      }
    }

    if (type === 'Vacacion'){
      if (getNumberOfDays() < 1){
        alert('Porfavor introduce un rango de fechas valido');
        return;
      }
      if (employeeForRequest.holidaysLeft === 0){
        alert(`Disculpa, este empleado no tiene mas vacaciones disponibles`);
        return;
      }
      if (getNumberOfDays() > employeeForRequest.holidaysLeft){
        alert(`Porfavor introduce un rango de fechas valido (dias disponibles: ${employeeForRequest.holidaysLeft})`);
        return;
      }

      if (employeeForRequest.holidaysTaken === 0){
        if (!isWeekend(startDate, endDate)){
          alert('Disculpa, de ser tu primer vacacion debe consistir de o incluir fin de semana')
          return;
        }
      }
    }

    setIsLoading(true);

    try {
      await addDoc(collection(db, "requests"), {
        uid: uuidv4(),
        type: type,
        status: (userData.name === 'Miguel Angel Flores Rivas' || userData?.role === 'hr' || type === 'Incapacidad') ? 'Aprobada' : 'Pendiente',
        startDate: startDate ? startDate : '-',
        endDate: endDate ? endDate : '-',
        reason: reason ? reason : '',
        numberOfDays: getNumberOfDays() ? getNumberOfDays() : '-',
        leaderChecked: false,
        employeeUid: userData?.role === 'hr' ? selectedEmployeeObject.uid : userData.uid,
        employeeName: userData?.role === 'hr' ? selectedEmployeeObject.name : userData.name,
        employeeCompany: userData?.role === 'hr' ? selectedEmployeeObject.company : userData.company,
        employeeEmail: userData?.role === 'hr' ? selectedEmployeeObject.email : userData.email,
        degreeOfIncapacity: level ? level : "-",
        date: permitDate ? permitDate : "-",
        hours: permitHours ? permitHours : "-",
        createdByType: userData?.role === 'hr' ? selectedEmployeeObject.role : userData.role,
        leaderResponsible: userData?.role === 'hr' ? selectedEmployeeObject.leader : userData.leader,
        leaderEmail: userData?.role === 'hr' ? selectedEmployeeObject.leaderEmail : userData.leaderEmail,
        currentPeriod: userData?.role === 'hr' ? selectedEmployeeObject.currentPeriod : userData.currentPeriod,
        holidaysTaken: userData?.role === 'hr' ? selectedEmployeeObject.holidaysTaken : userData.holidaysTaken,
        dateRequested: new Date().toISOString(),
        accountantName: userData?.role === 'hr' ? selectedEmployeeObject.accountantName : userData.accountantName,
        accountantEmail: userData?.role === 'hr' ? selectedEmployeeObject.accountantEmail : userData.accountantEmail,
      });

      if (userData?.role === 'hr' && type !== 'Permiso'){
        const userRef = query(collection(db, "users"), where("uid", "==", selectedEmployeeObject.uid));
        const findUsers = await getDocs(userRef);
        const data = findUsers.docs[0].data();
        findUsers.forEach( async (user) => {
          const getUser = doc(db, 'users', user.id);
          await updateDoc(getUser, {
            holidaysLeft: parseInt(data.holidaysLeft) - parseInt(getNumberOfDays()),
            holidaysTaken: parseInt(data.holidaysTaken) + parseInt(getNumberOfDays()),
          });
        });
      }

      setType();
      setLevel();
      setReason();
      setStartDate();
      setEndDate();
      setPermitDate();
      setPermitHours();

      // eslint-disable-next-line
      var templateParams = {
        from_name: userData.name,
        request_type: type,
        leader_email: userData.leaderEmail
      };

      const leaderEmail = userData.leaderEmail
      const leaderName = userData.leader

      if (userData?.role !== 'hr'){
      if (type === 'Incapacidad'){
        await addDoc(collection(db, "mail"), {
          to: [
            'camila.guzman@gmaritimo.com'
            // 'sprangerventures@gmail.com'
          ],
          message: {
            subject: "¡Tienes un nuevo registro de incapacidad!",
            html: `Hola <b>Camila</b>,<br/>
              <br/>
              <b>${userData.name}</b> ha registrado una incapacidad:<br/>
              <b>Nombre del solicitante: </b>${userData.name}<br/>
              <b>Fecha de inicio: </b>${startDate ? (new Date(startDate).toLocaleDateString("de-DE")) : '-'}<br/>
              <b>Fecha final: </b>${endDate ? (new Date(endDate).toLocaleDateString("de-DE")) : '-'}<br/>
              <b>Numero de dias: </b>${getNumberOfDays() ? getNumberOfDays() : '-'}
              <b>Razon: </b>${reason ? reason : ""}
              <br/>
              <br/>
              Cualquier duda o comentario favor comunicarse al correo camila.guzman@gmaritimo.com`
          }
        });
        await addDoc(collection(db, "mail"), {
          to: [
            userData.email
            // 'sprangerventures@gmail.com'
          ],
          message: {
            subject: "Tu incapacidad ha sido registrada",
            html: `Hola <b>${userData.name}</b>,<br/>
              <br/>
              <b>Tu incapacidad ha sido registrada:<br/>
              <b>Nombre del solicitante: </b>${userData.name}<br/>
              <b>Fecha de inicio: </b>${startDate ? (new Date(startDate).toLocaleDateString("de-DE")) : '-'}<br/>
              <b>Fecha final: </b>${endDate ? (new Date(endDate).toLocaleDateString("de-DE")) : '-'}<br/>
              <b>Numero de dias: </b>${getNumberOfDays() ? getNumberOfDays() : '-'}
              <b>Razon: </b>${reason ? reason : ""}
              <br/>
              <br/>
              Cualquier duda o comentario favor comunicarse al correo camila.guzman@gmaritimo.com`
          }
        });
      } else {
      await addDoc(collection(db, "mail"), {
        to: [
          userData?.role === 'employee' ? leaderEmail : 'camila.guzman@gmaritimo.com'
          // 'sprangerventures@gmail.com'
        ],
        message: {
          subject: `¡Tienes una nueva solicitud de ${type.toLowerCase()}!`,
          html: `Hola <b>${userData?.role === 'employee' ? leaderName : 'Camila'},</b><br/>
            <br/>
            Tienes una nueva solicitud de parte de <b>${userData.name}</b>:<br/>
            <b>Nombre del solicitante: </b>${userData.name}<br/>
            ${
              type === 'Permiso' ?
                `
                <b>Fecha: </b>${permitDate ? (new Date(permitDate).toLocaleDateString("de-DE")) : "-"}<br/>
                <b>Numero de horas: </b>${permitHours ? permitHours : "-"}
                `
              :
                `
                <b>Fecha de inicio: </b>${startDate ? (new Date(startDate).toLocaleDateString("de-DE")) : '-'}<br/>
                <b>Fecha final: </b>${endDate ? (new Date(endDate).toLocaleDateString("de-DE")) : '-'}<br/>
                <b>Numero de dias: </b>${getNumberOfDays() ? getNumberOfDays() : '-'}<br/>
                <br/>
                Informacion de referencia:<br/>
                <b>Dias gozados (periodo ${userData.currentPeriod} - ${(parseInt(userData.currentPeriod)) + 1}): </b>${userData.holidaysTaken}<br/>
                `
            }
            <br/>
            ${userData?.role === 'employee' ? 'Ingresa a la app para aceptar o rechazar su solicitud <a href="https://vacaciones.grupomaritimo.com/" target="_blank">aqui</a>' : 'Ingresa a la app para aprobar o denegar su solicitud <a href="https://vacaciones.grupomaritimo.com/" target="_blank">aqui</a>'}<br/>
            <br/>
            Cualquier duda o comentario favor comunicarse al correo camila.guzman@gmaritimo.com`
        }
      });
      }
      }

      navigate(-1);

      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  }

  const daysFromEntry = () => {
    var d1 = new Date(userData?.dateOfEntry);
    var d2 = new Date();

    return (d2-d1)/(1000*3600*24);
  }

  if (isLoading) return (
    <div className='loadingPage'>
      <BarLoader color="#CCA449" />
    </div>
  )

  return (
    <div id="createRequestPage" className='page'> 
      <h1>
        <MdChevronLeft onClick={() => navigate(-1)} />
        Nueva solicitud
      </h1>
      <div style={{marginBottom: '1rem'}}>
        {/* <p className='label'>
          Nombre
        </p>
        <Input
          type="text"
          className="mainInput"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Escoge una fecha"
        /> */}
        {/* <p className='label'>
          Periodo
        </p>
        <Input
          type="text"
          className="mainInput"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Escoge una fecha"
        /> */}
        {
          userData?.role === 'hr' ?
            <div>
              <p className='label'>
                Empleado
              </p>
              <Input
                type="select"
                className="mainInput"
                value={selectedEmployee}
                onChange={(e) => setSelectedEmployee(e.target.value)}
                defaultValue={"default"}
              >
                <option disabled value={"default"}>El empleado para la solicitud</option>
                {
                  employees?.map(employee => {
                    return (
                      <option value={employee.name}>{employee.name}</option>
                    )
                  })
                }
              </Input>
            </div>
          : null
        }
        <p className='label'>
          Tipo
        </p>
        <Input
          type="select"
          className="mainInput"
          value={type}
          onChange={(e) => setType(e.target.value)}
          defaultValue={"default"}
        >
          <option disabled value={"default"}>El tipo de solicitud</option>
          <option value={"Incapacidad"}>Incapacidad</option>
          <option value={"Permiso"}>Permiso</option>
          {
            daysFromEntry() > 200 ?
              <option value={"Vacacion"}>Vacacion</option>
              : null
          }
        </Input>
        {
          type === 'Incapacidad' ?
            <div>
              <p className='label'>
                Nivel
              </p>
              <Input
                type="select"
                className="mainInput"
                value={level}
                onChange={(e) => setLevel(e.target.value)}
                defaultValue={"default"}
              >
                <option disabled value={"default"}>El nivel de gravedad de la incapacidad</option>
                <option value={"Leve"}>Leve</option>
                <option value={"Mediano"}>Mediano</option>
                <option value={"Grave"}>Grave</option>
              </Input>
            </div>
          : null
        }
        {
          !(type === 'Vacacion') ?
            <div>
              <p className='label'>
                Razon
              </p>
              <Input
                type="text"
                className="mainInput"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                placeholder="La razon de la solicitud"
              />
            </div>
          : null
        }
        {
        type !== 'Permiso' ?
        <div>
        <p className='label'>
          Fecha inicio
        </p>
        <DatePicker
          id="example-datepicker"
          className='mainInput'
          style={{borderRadius: 10}}
          showClearButton={false}
          value={startDate} 
          onChange={(v) => setStartDate(v)}
          dateFormat="DD.MM.YYYY"
          placeholder="La fecha de inicio de la solicitud"
        />
        <p className='label'>
          Fecha final
        </p>
        <DatePicker
          id="example-datepicker"
          className='mainInput'
          style={{borderRadius: 10}}
          showClearButton={false}
          value={endDate} 
          onChange={(v) => setEndDate(v)}
          dateFormat="DD.MM.YYYY"
          placeholder="La fecha final de la solicitud"
        />
        <p className='label'>
          Dias
        </p>
        <Input
          type="number"
          className="mainInput"
          value={getNumberOfDays()}
          placeholder="El numero de dias a solicitar"
          disabled
        />
        </div>
        :
        <div>
          <p className='label'>
            Fecha
          </p>
          <DatePicker
            id="example-datepicker"
            className='mainInput'
            style={{borderRadius: 10}}
            showClearButton={false}
            value={permitDate} 
            onChange={(v) => setPermitDate(v)}
            dateFormat="DD.MM.YYYY"
            placeholder="La fecha de la solicitud"
          />
          <p className='label'>
            Horas
          </p>
          <Input
            type="number"
            className="mainInput"
            value={permitHours}
            onChange={(e) => setPermitHours(e.target.value)}
            placeholder="El numero de horas a solicitar"
            min={1}
            max={4}
          />
        </div>
        }
      </div>
      <Button onClick={createRequest} className='primaryBtn'>
        Crear
      </Button>
    </div>
  );
}

export default CreateRequestPage;