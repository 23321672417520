import { useState } from 'react'

import { auth, sendPasswordReset } from "../../util/firebase";
import { useAuthState } from "react-firebase-hooks/auth";

import { Link, useNavigate } from "react-router-dom";

import BarLoader from "react-spinners/BarLoader";

import { Input, Button } from 'reactstrap';

function ResetPasswordPage() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const [loading] = useAuthState(auth);

  const resetPassword = () => {
    sendPasswordReset(email);
    navigate(-1);
  }

  if (loading) return (
    <div className='loadingPage'>
      <BarLoader color="#CCA449" />
    </div>
  )

  return (
    <div id="loginPage">   
      <div className='loginContainer'>
        <img
          alt="logo"
          src="https://firebasestorage.googleapis.com/v0/b/grupo-maritimo.appspot.com/o/assets%2Flogogm2020%20(1).PNG?alt=media&token=5e2c58ff-e31b-480b-9188-5fecdecbf81a"
          style={{
            width: 250,
          }}
        />
         <div style={{display: 'flex', flexDirection: 'column'}}>
        <Input
          type="text"
          className="authInput"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Tu correo"
        />
        </div>
        <Button className='primaryBtn' onClick={resetPassword} style={{marginTop: '0.5rem'}}>
          Restablecer
        </Button>     
        <p>¿No tienes cuenta? <Link className='authLink' to="/register">Crea tu cuenta</Link> aqui.</p>
      </div>
    </div>
  );
}

export default ResetPasswordPage;