import { useState, useEffect } from 'react'

import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, logout, storage } from "../../util/firebase";
import { query, collection, getDocs, where, updateDoc, doc, addDoc } from "firebase/firestore";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

import Swal from 'sweetalert2'

import BarLoader from "react-spinners/BarLoader";

import { IoClose } from 'react-icons/io5';

import { Button, Input, Modal, ModalBody } from 'reactstrap'

function AccountPage() {
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false)

  const [user] = useAuthState(auth);

  const [userData, setUserData] = useState();
  const [userName, setUserName] = useState();
  const [userEmail, setUserEmail] = useState();
  const [userPhoneNumber, setUserPhoneNumber] = useState();
  const [newUserPhoneNumber, setNewUserPhoneNumber] = useState();
  const [userProfilePicture, setUserProfilePicture] = useState();

  const fetchUserData = async () => {
    try {
      setIsLoading(true);
      const q = query(collection(db, "users"), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setUserData(data);
      setUserName(data.name);
      setUserEmail(data.email);
      setUserPhoneNumber(data.phoneNumber);
      setUserProfilePicture(data.profilePicture)
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  };

  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line
  }, [user]);

  const [phoneNumberChangeRequest, setPhoneNumberChangeRequest] = useState();

  const fetchPhoneNumberChangeRequest = async () => {
    try {
      setIsLoading(true);

      const q = query(collection(db, "phoneNumberChangeRequests"), where("userUid", "==", user?.uid));
      const doc = await getDocs(q);
      
      if (doc.docs.length > 0){
        const data = doc.docs[0].data();
        setPhoneNumberChangeRequest(data);
      }

      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching phone number change request");
    }
  };

  useEffect(() => {
    fetchPhoneNumberChangeRequest();
    // eslint-disable-next-line
  }, [user]);

  const [changePhoneNumberModalIsVisible, setChangePhoneNumberModalIsVisible] = useState(false);
  const toggleChangePhoneNumberModal = () => {
    setChangePhoneNumberModalIsVisible(!changePhoneNumberModalIsVisible);
  }

  const requestChangePhoneNumber = async () => {
    setIsLoading(true);

    if (phoneNumberChangeRequest){
      try {
        const requestRef = query(collection(db, "phoneNumberChangeRequests"), where("userUid", "==", phoneNumberChangeRequest?.userUid));
        const findRequests = await getDocs(requestRef);
        findRequests.forEach( async (request) => {
          const getRequest = doc(db, 'phoneNumberChangeRequests', request.id);
          await updateDoc(getRequest, {
            status: 'pending',
            oldUserPhoneNumber: userPhoneNumber,
            newUserPhoneNumber: newUserPhoneNumber
          });
        });
        fetchPhoneNumberChangeRequest();
        toggleChangePhoneNumberModal();
      } catch (err) {
        console.error(err);
        alert(err.message);
      }
    } else {
    try {
      await addDoc(collection(db, "phoneNumberChangeRequests"), {
        userUid: user.uid,
        userName: userName,
        oldUserPhoneNumber: userPhoneNumber,
        newUserPhoneNumber: newUserPhoneNumber
      });
      toggleChangePhoneNumberModal();
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
    }
    
    setIsLoading(false);
  }

  const changePhoneNumber = async () => {
    setIsLoading(true);

    try {
      const userRef = query(collection(db, "users"), where("uid", "==", user?.uid));
      const findUsers = await getDocs(userRef);
      findUsers.forEach( async (user) => {
        const getUser = doc(db, 'users', user.id);
        await updateDoc(getUser, {
          phoneNumber: newUserPhoneNumber
        });
      });
      fetchUserData();
      toggleChangePhoneNumberModal();
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
    
    setIsLoading(false);
  }

  const logoutUser = () => {
    Swal.fire({
      text: "¿Estas segur@ que quieres salir?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#CCA449',
      confirmButtonText: "Si, salir",
      cancelButtonText: "No, cancelar"
    }).then((result) => {
      if (result.isConfirmed) {
        logout()
      }
    })
  }

  const [changeProfilePictureModalIsVisible, setChangeProfilePictureModalIsVisible] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);

  const [newProfilePicture, setNewProfilePicture] = useState("");
  const [newProfilePicturePreview, setNewProfilePicturePreview] = useState("");

  const toggleChangeProfilePictureModal = () => {
    if (changeProfilePictureModalIsVisible) {
      setChangeProfilePictureModalIsVisible(false);
      setNewProfilePicture("");
    } else {
      setChangeProfilePictureModalIsVisible(true);
    }
  }

  const selectImage = () => {
    document.getElementById("selectImage").click()
  }
 
  // Handles input change event and updates state
  const handleChange = (event) => {
    setNewProfilePicture(event.target.files[0]);
    setNewProfilePicturePreview(URL.createObjectURL(event.target.files[0]));
  }

  const handleUpload = () => {
    const storageRef = ref(storage, `profilePictures/${user?.uid}/${newProfilePicture.name}`);
    const uploadTask = uploadBytesResumable(storageRef, newProfilePicture);
    setIsModalLoading(true);
    uploadTask.on(
      "state_changed", null ,
      (error) => console.log(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          console.log("File available at", downloadURL);
          try {
            const userRef = query(collection(db, "users"), where("uid", "==", user?.uid));
            const findUsers = await getDocs(userRef);
            findUsers.forEach( async (user) => {
              const getUser = doc(db, 'users', user.id);
              await updateDoc(getUser, {
                profilePicture: downloadURL
              });
            });
            fetchUserData();
            setIsModalLoading(false);
            toggleChangeProfilePictureModal();
          } catch (err) {
            console.error(err);
            alert(err.message);
          }
        });
      }
    );
  }

  if (isLoading) return (
    <div className='loadingPage'>
      <BarLoader color='#CCA449' />
    </div>
  )

  return (
    <div id="accountPage" className='page'>
      <h1>
        Tu cuenta
      </h1>
      <p className='label' style={{marginBottom: '0.5rem'}}>
        Foto de perfil
      </p>
      <img
        alt="logo"
        src={userProfilePicture ? userProfilePicture : "https://res.cloudinary.com/da3qtg7t5/image/upload/v1683238965/Swift/Default_pfp.svg_ufwfdc.png"}
        className='userProfilePicture'
        onClick={toggleChangeProfilePictureModal}
      />
      <Modal id='changeProfilePictureModal' isOpen={changeProfilePictureModalIsVisible} toggle={toggleChangeProfilePictureModal} size={'md'}>
        <ModalBody>
          <div className='modalHeader' style={{marginBottom: '1rem'}}>
            <div></div>
						<IoClose onClick={toggleChangeProfilePictureModal} />
					</div>
          {isModalLoading ?
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: 410}}>
              <BarLoader color='#CCA449' />
            </div>
            :
          <div>
            <h3>
              Cambiar foto
            </h3>
            <div id='changeProfilePictureModalImgContainer'>
              <img
                alt="logo"
                src={newProfilePicturePreview ? newProfilePicturePreview : userProfilePicture ?userProfilePicture : "https://res.cloudinary.com/da3qtg7t5/image/upload/v1683238965/Swift/Default_pfp.svg_ufwfdc.png"}
                className='userProfilePicture'
              />
            </div>
            <Input
              id='selectImage'
              type="file"
              className="mainInput"
              accept='image/*'
              onChange={handleChange}
              style={{display: 'none'}}
            />
            <Button onClick={selectImage} className='secondaryBtn' style={{marginBottom: '0.5rem'}}>
              {
                newProfilePicture ? newProfilePicture.name : 'Escoger foto'
              }
            </Button>
            <Button onClick={handleUpload} disabled={!newProfilePicture} className='primaryBtn'>
              Guardar
            </Button>
          </div>
          }
				</ModalBody>
			</Modal>
      <p className='label' style={{marginTop: '0.5rem'}}>
        Nombre
      </p>
      <Input
        type="text"
        className="mainInput"
        value={userName}
        onChange={(e) => setUserName(e.target.value)}
        placeholder="Escoge una fecha"
        disabled
      />
      <p className='label'>
        Correo
      </p>
      <Input
        type="text"
        className="mainInput"
        value={userEmail}
        onChange={(e) => setUserEmail(e.target.value)}
        placeholder="Escoge una fecha"
        disabled
      />
      <div>
        <p className='label'>
          Telefono
        </p>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Input
            type="text"
            className="mainInput"
            value={userPhoneNumber}
            onChange={(e) => setUserPhoneNumber(e.target.value)}
            placeholder="Tu numero de telefono"
            disabled
            style={{marginRight: '1rem'}}
          />
          <Button onClick={toggleChangePhoneNumberModal} className='primaryBtn' style={{width: 'min-content', paddingLeft: 30, paddingRight: 30}}>
            Cambiar
          </Button>
          <Modal id='changePhoneNumberModal' isOpen={changePhoneNumberModalIsVisible} toggle={toggleChangePhoneNumberModal} size={'md'}>
            <ModalBody>
              <div className='modalHeader' style={{marginBottom: '1rem'}}>
                <div></div>
						    <IoClose onClick={toggleChangePhoneNumberModal} />
					    </div>
              <div>
                <h3>
                  {userData?.role === 'employee' ? 'Solicitar cambio' : 'Cambiar telefono'}
                </h3>
                {
                  !phoneNumberChangeRequest ? 
                <div>
                  <Input
                    type="text"
                    className="mainInput"
                    value={newUserPhoneNumber}
                    onChange={(e) => setNewUserPhoneNumber(e.target.value)}
                    placeholder="Nuevo numero de telefono"
                    style={{marginBottom: '1rem'}}
                  />
                  <Button onClick={userData?.role === 'employee' ? requestChangePhoneNumber : changePhoneNumber} className='primaryBtn'>
                    Cambiar
                  </Button>
                </div>
                  :
                  <div>
                    {phoneNumberChangeRequest?.status === 'pending' ?
                      <p>
                        Pendiente
                      </p>
                      :
                      phoneNumberChangeRequest?.status === 'approved' ?
                        <div>
                          <p>
                            Cambio anterior aceptado
                          </p>
                          <Input
                            type="text"
                            className="mainInput"
                            value={newUserPhoneNumber}
                            onChange={(e) => setNewUserPhoneNumber(e.target.value)}
                            placeholder="Nuevo numero de telefono"
                            style={{marginBottom: '1rem'}}
                          />
                          <Button onClick={userData?.role === 'employee' ? requestChangePhoneNumber : changePhoneNumber} className='primaryBtn'>
                            Cambiar
                          </Button>
                        </div>
                          :
                        <div>
                          <p>
                            Cambio anterior denegado
                          </p>
                          <Input
                            type="text"
                            className="mainInput"
                            value={newUserPhoneNumber}
                            onChange={(e) => setNewUserPhoneNumber(e.target.value)}
                            placeholder="Nuevo numero de telefono"
                            style={{marginBottom: '1rem'}}
                          />
                          <Button onClick={userData?.role === 'employee' ? requestChangePhoneNumber : changePhoneNumber} className='primaryBtn'>
                            Cambiar
                          </Button>
                        </div>
                    }
                  </div>
                }
              </div>
				    </ModalBody>
			    </Modal>
        </div>
      </div>
      {/* <p className='label'>
        Contraseña
      </p>
      <Input
        type="text"
        className="mainInput"
        value={userPhoneNumber}
        onChange={(e) => setUserPhoneNumber(e.target.value)}
        placeholder="Escoge una fecha"
      /> */}
      <Button onClick={logoutUser} className='primaryBtn' style={{marginTop: '1rem'}}>
        Cerrar sesion
      </Button>
    </div>
  );
}

export default AccountPage;