import { useState, useEffect } from 'react';

import Swal from 'sweetalert2'

import { db } from "../../util/firebase";
import { query, collection, getDocs, addDoc } from "firebase/firestore";

import { useNavigate } from "react-router-dom";

import BarLoader from "react-spinners/BarLoader";
import { MdChevronLeft } from 'react-icons/md';

import { Button, Input } from 'reactstrap';

function AdminCreateCompanyPage() {

  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const [companies, setCompanies] = useState();
  const fetchCompanies = async () => {
    try {
      setIsLoading(true);
  
      let fetchedCompanies = [];
      const companiesRef = collection(db, "companies");
      const q = query(companiesRef)
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const newCompany = doc.data()
        fetchedCompanies.push(newCompany)
      });
  
      setCompanies(fetchedCompanies);
  
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching companies");
    }
  }
  useEffect(() => {    
    fetchCompanies();
  }, []);

  const [newCompanyName, setNewCompanyName] = useState();

  const createCompany = async () => {
    if (companies?.length > 0){
      if ((companies.filter(e => e.name.toLowerCase() === newCompanyName.toLowerCase()).length > 0)) {
        Swal.fire({
          text: "Esta empresa ya existe en el sistema",
          icon: 'warning',
          confirmButtonColor: '#CCA449',
          confirmButtonText: "Okay",
        })
      } else {
        setIsLoading(true)
        
        try {
          await addDoc(collection(db, "companies"), {
            name: newCompanyName
          });

          navigate(-1);

          Swal.fire({
            text: "Nueva empresa agregada exitosamente",
            icon: 'success',
            confirmButtonColor: '#CCA449',
            confirmButtonText: "Okay",
          })

          setIsLoading(false);
        } catch (err) {
          console.error(err);
          alert("An error occured while creating company");
          setIsLoading(false);
        }
      }
    }
  }

  if (isLoading) return (
    <div className='loadingPage'>
      <BarLoader color="#CCA449" />
    </div>
  )

  return (
    <div id="helpPage" className='page'> 
      <h1>
        <MdChevronLeft onClick={() => navigate(-1)} />
        Agregar empresa
      </h1>
      <div>
        <p className='label'>
          Nombre
        </p>
        <Input
          type="text"
          className="mainInput"
          value={newCompanyName}
          onChange={(e) => setNewCompanyName(e.target.value)}
          placeholder="El nombre de la empresa a agregar"
        />
        <Button onClick={createCompany} className='primaryBtn' style={{marginTop: '0.5rem'}} disabled={!newCompanyName}>
          Agregar
        </Button>
      </div>
    </div>
  );
}

export default AdminCreateCompanyPage;