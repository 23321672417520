// eslint-disable-next-line
import { useState, useEffect } from 'react'

import { auth, logInWithEmailAndPassword } from "../../util/firebase";

import { useAuthState } from "react-firebase-hooks/auth";

import { 
  Link, 
  // eslint-disable-next-line
  useNavigate 
} from "react-router-dom";

import BarLoader from "react-spinners/BarLoader";

import { Input, Button } from 'reactstrap';

import { BiShow, BiHide } from "react-icons/bi";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading] = useAuthState(auth);

  const [passwordInputType, setPasswordInputType] = useState('password');

  const togglePasswordInputType = () => {
    if (passwordInputType === 'password'){
      setPasswordInputType('text');
    } else {
      setPasswordInputType('password')
    }
  }

  if (loading) return (
    <div className='loadingPage'>
      <BarLoader color='#CCA449' />
    </div>
  )

  return (
    <div id="loginPage">
      <div className='loginContainer'>       
        <img
          alt="logo"
          src="https://firebasestorage.googleapis.com/v0/b/grupo-maritimo.appspot.com/o/assets%2Flogogm2020%20(1).PNG?alt=media&token=5e2c58ff-e31b-480b-9188-5fecdecbf81a"
          style={{
            width: 250
          }}
        />
        <div style={{display: 'flex', flexDirection: 'column'}}>
        <Input
          type="text"
          className="authInput"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Tu correo"
        />
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '0.5rem'}}>
        <Input
          type={passwordInputType}
          className="authInput"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Tu contraseña"
          style={{marginBottom: 0}}
        />
        {
          passwordInputType === 'password' ? <BiShow onClick={() => togglePasswordInputType()} style={{width: 25, height: 25, marginLeft: '0.5rem', cursor: 'pointer', color: '#CCA449'}} /> : <BiHide onClick={() => togglePasswordInputType()} style={{width: 25, height: 25, marginLeft: '0.5rem', cursor: 'pointer', color: '#CCA449'}} />
        }
        </div>
        </div>   
        <Button className='primaryBtn' onClick={() => logInWithEmailAndPassword(email, password)} style={{marginTop: '0.5rem'}}>
          Iniciar sesion
        </Button>    
        <Link className='authLink' to="/reset">¿Olvidaste tu contraseña?</Link>
        <p>¿No tienes cuenta? <Link className='authLink' to="/register">Crea tu cuenta</Link> aqui.</p>
      </div>
    </div>
  );
}

export default LoginPage;